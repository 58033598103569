function PastChampsExternalLinks() {
    return (
        <div style={{marginLeft: "10px"}}>
            <h3 className="font-bold text-lg text-blue-600">Past Champion Lists from Other Sites</h3>
            <br />
            <div>TPG DOD 6v6 Past Champions from September 23rd, 2015 on <a className="text-blue-500 underline" href="https://web.archive.org/web/20150923223019/http://www.tpgleague.org/dod6/pastchamps/">archive.org</a></div>
            <br/>
            <div>TPG DOD 6v6 Past Champions on <a className="text-blue-500 underline" href="https://sp3c-dod.github.io/pastchamps.html#tpg">sp3c.org</a></div>
            <div>TPG DOD 3v3 Past Champions on <a className="text-blue-500 underline" href="https://sp3c-dod.github.io/pastchamps.html#tpg3v3">sp3c.org</a></div>
            <div>TPG DOD 7v7 Past Champions on <a className="text-blue-500 underline" href="https://sp3c-dod.github.io/pastchamps.html#tpg7v7">sp3c.org</a></div>
            <div>TPG DOD TPG2 Past Champions on <a className="text-blue-500 underline" href="https://sp3c-dod.github.io/pastchamps.html#tpg2">sp3c.org</a></div>
            <div>TPG DOD Source 6v6 Past Champions on <a className="text-blue-500 underline" href="https://sp3c-dod.github.io/pastchamps.html#tpg2">sp3c.org</a></div>
            <div>TPG DOD Source 3v3 Past Champions on <a className="text-blue-500 underline" href="https://sp3c-dod.github.io/pastchamps.html#tpg3v3source">sp3c.org</a></div>
            <div>TPG DOD 6v6/7v7 Champions Season 1-5 on <a className="text-blue-500 underline" href="https://web.archive.org/web/20040210013646/http://www.tpgleague.org:80/tpghistory.txt">archive.org</a></div>
            <br/>
            <div>DOD/DOD Source Past Champions Page on <a className="text-blue-500 underline" href="https://sp3c-dod.github.io/pastchamps.html">sp3c.org</a></div>
            <div>KTP Past Champions Page on <a className="text-blue-500 underline" href="https://challonge.com/communities/ktpleague/tournaments.html">challonge.com</a></div>
        </div>
    )
  };

  export default PastChampsExternalLinks;