export function tryHyperlinkUserProvidedUrl(url) {
    let formattedUrl;
    if (url){
        // If the URL appears valid then hyperlink what the user inputted
        if (url.startsWith("http")) {
        formattedUrl = <a href={url} className="text-blue-500 underline">{url}</a>;
        }
        else {
        // If the URL is not valid then simply return what the user inputted
        formattedUrl = url;
        }
    }
    else {
        // If no URL was entered by the user return an empty string
        formattedUrl = "";
    }

    return formattedUrl;
}

export function tryHyperlinkUserProvidedSteamUrl(steamUrl){
    let formattedUrl;
    if (steamUrl){
      // If the URL appears valid then hyperlink what the user inputted
      if (steamUrl.startsWith("http://steamcommunity.com/id") || steamUrl.startsWith("http://steamcommunity.com/profiles")) {
        formattedUrl = <a href={steamUrl} className="text-blue-500 underline">{steamUrl}</a>;
      }
      else {
        // If the URL is not valid then simply return what the user inputted
        formattedUrl = steamUrl;
      }
    }
    else {
      // If no URL was entered by the user return an empty string
      formattedUrl = "";
    }

    return formattedUrl;
  }

  export function tryGetAbsoluteTpgFilesUrl(filesUrl) {
    let absoluteUrl;

    if (filesUrl && filesUrl.startsWith("/")) {
      absoluteUrl = "http://files.tpgleague.org" + filesUrl;
    }
    else {
      absoluteUrl = filesUrl;
    }

    return absoluteUrl;
  }