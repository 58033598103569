import { Link } from "react-router-dom";
import bracket from '../../images/bracket.png';
import demo from '../../images/demo.png';

export default function Dod3v3PastChamps() {
    return (
        <div>

        <h3 className="ml-1 font-bold text-lg text-blue-600">TPG Day of Defeat 3v3 (9/12/04 - 9/17/13)</h3>
        <table className="pastchamps">
        <tr>
            <th title="Hover over the season number for the full date range of the season">Season</th>
            <th colspan="2">Lower/Open</th>
            <th colspan="2">Middle</th>
            <th colspan="2">Upper</th>
        </tr>
        <tr>
            <td><span title="3/15/11 - 6/22/11">17 <span className="text-xs">(Spring 2011)</span></span></td>
            <td><Link className="underline decoration-dotted" to={"/team/1081"}>dgf</Link></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td><span title="7/25/10 - 7/7/10">16 <span className="text-xs">(Summer 2010)</span></span></td>
            <td><Link className="underline decoration-dotted" to={"/team/1163"}>Sem Dó Nem Piedade</Link></td>
            <td className="centerrowicon"><a href="http://files.tpgleague.org/3v3bracket.jpg" target="_blank" rel="noreferrer"><img alt="bracket" src={bracket} /></a></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td><span title="7/22/09 - 9/23/09">15 <span className="text-xs">(Summer 2009)</span></span></td>
            <td><Link className="underline decoration-dotted" to={"/team/1079"}>The Kool Kids Klub</Link></td>
            <td className="centerrowicon"><a href="http://files.tpgleague.org/dod3v3/s15_playoffs.gif" target="_blank" rel="noreferrer"><img alt="bracket" src={bracket} /></a></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td><span title="9/15/08 - 12/8/08">14 <span className="text-xs">(Fall 2008)</span></span></td>
            <td><Link className="underline decoration-dotted" to={"/team/749"}>[JAZ&amp;CO]</Link></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td><span title="6/2/08 - 8/18/08">13 <span className="text-xs">(Summer 2008)</span></span></td>
            <td><Link className="underline decoration-dotted" to={"/team/34"}>Shadow Gaming</Link></td>
            <td className="centerrowicon"><a href="http://files.tpgleague.org/dod3v3/s13_playoffs.gif" target="_blank" rel="noreferrer"><img alt="bracket" src={bracket} /></a></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td><span title="2/28/08 - 5/19/08">12 <span className="text-xs">(Winter 2008)</span></span></td>
            <td><Link className="underline decoration-dotted" to={"/team/111"}>Total Annihilation²</Link></td>
            <td className="centerrowicon"><a href="http://files.tpgleague.org/dod3v3/s12_playoffs.gif" target="_blank" rel="noreferrer"><img alt="bracket" src={bracket} /></a></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td><span title="10/29/07 - 2/12/08">11 <span className="text-xs">(Fall 2007)</span></span></td>
            <td><Link className="underline decoration-dotted" to={"/team/520"}>NorthSquad</Link></td>
            <td className="centerrowicon"><a href="http://files.tpgleague.org/dod3v3/s11_lower.gif" target="_blank" rel="noreferrer"><img alt="bracket" src={bracket} /></a></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td><Link className="underline decoration-dotted" to={"/team/111"}>Total Annihilation²</Link></td>
            <td className="centerrowicon"><a href="http://files.tpgleague.org/dod3v3/s11_upper.gif" target="_blank" rel="noreferrer"><img alt="bracket" src={bracket} /></a></td>
        </tr>
        <tr>
            <td><span title="7/16/07 - 10/22/07">10 <span className="text-xs">(Summer 2007)</span></span></td>
            <td><Link className="underline decoration-dotted" to={"/team/625"}>Organization of the Zodiac</Link></td>
            <td className="centerrowicon"><a href="http://files.tpgleague.org/dod3v3/s10_lower.gif" target="_blank" rel="noreferrer"><img alt="bracket" src={bracket} /></a></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td><Link className="underline decoration-dotted" to={"/team/409"}>Emergency Measures Taken!</Link></td>
            <td className="centerrowicon"><a href="http://files.tpgleague.org/dod3v3/s10_upper.gif" target="_blank" rel="noreferrer"><img alt="bracket" src={bracket} /></a><a title="Submitted dispute demos from avatar of EMT" href="http://files.tpgleague.org/dod3v3/demos/s10final-avatar.zip"> <img alt="film reel" src={demo} /></a></td>
        </tr>
        <tr>
            <td><span title="4/2/07 - 7/9/07">9 <span className="text-xs">(Spring 2007)</span></span></td>
            <td><Link className="underline decoration-dotted" to={"/team/500"}>Up In Smoke</Link></td>
            <td className="centerrowicon"><a href="http://files.tpgleague.org/dod3v3/s9_lower.gif" target="_blank" rel="noreferrer"><img alt="bracket" src={bracket} /></a></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td><Link className="underline decoration-dotted" to={"/team/199"}>Sly Player |s Near</Link></td>
            <td className="centerrowicon"><a href="http://files.tpgleague.org/dod3v3/s9_upper.gif" target="_blank" rel="noreferrer"><img alt="bracket" src={bracket} /></a></td>
        </tr>
        <tr>
            <td><span title="12/4/06 - 3/19/07">8 <span className="text-xs">(Winter 2007)</span></span></td>
            <td><Link className="underline decoration-dotted" to={"/team/159"}>Multi Kill Teammates</Link></td>
            <td className="centerrowicon"><a href="http://files.tpgleague.org/dod3v3/s8_lower.gif" target="_blank" rel="noreferrer"><img alt="bracket" src={bracket} /></a></td>
            <td><Link className="underline decoration-dotted" to={"/team/135"}>Cute Animal Cult</Link></td>
            <td className="centerrowicon"><a href="http://files.tpgleague.org/dod3v3/s8_middle.gif" target="_blank" rel="noreferrer"><img alt="bracket" src={bracket} /></a></td>
            <td><Link className="underline decoration-dotted" to={"/team/106"}>iCON</Link></td>
            <td className="centerrowicon"><a href="http://files.tpgleague.org/dod3v3/s8_upper.gif" target="_blank" rel="noreferrer"><img alt="bracket" src={bracket} /></a></td>
        </tr>
        {/* old site starts here */}
        <tr>
            <td><span title="7/24/06 - 11/06/06">7 <span className="text-xs">(Fall 2006)</span></span></td>
            <td><a className="underline decoration-dotted" href="http://washedup.site.nfoservers.com/oldtpg/3v3.tpgleague.org/team.info.asp%40id=225.html" target="_blank" rel="noreferrer">Last Full Measure</a></td>
            <td className="centerrowicon"><a href="http://files.tpgleague.org/dod3v3/s7_lower.gif" target="_blank" rel="noreferrer"><img alt="bracket" src={bracket} /></a></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td><a className="underline decoration-dotted" href="http://washedup.site.nfoservers.com/oldtpg/3v3.tpgleague.org/team.info.asp%40id=265.html" target="_blank" rel="noreferrer">LoLLerBombs</a></td>
            <td className="centerrowicon"><a href="http://files.tpgleague.org/dod3v3/s7_upper.gif" target="_blank" rel="noreferrer"><img alt="bracket" src={bracket} /></a></td>
        </tr>
        <tr>
            <td><span title="3/27/06 - 6/26/06">6 <span className="text-xs">(Spring 2006)</span></span></td>
            <td><i>None Declared</i>*</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td><a className="underline decoration-dotted" href="http://washedup.site.nfoservers.com/oldtpg/3v3.tpgleague.org/team.info.asp%40id=130.html" target="_blank" rel="noreferrer">The Unbathed and Unshaved</a></td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td><span title="12/12/05 - 03/20/06">5 <span className="text-xs">(Winter 2006)</span></span></td>
            <td><a className="underline decoration-dotted" href="https://web.archive.org/web/20060211172006/http://3v3.tpgleague.org/team.info.asp?id=140" target="_blank" rel="noreferrer">Storm Troopers</a></td>
            <td className="centerrowicon"><a href="http://files.tpgleague.org/dod3v3/s5_playoffs.gif" target="_blank" rel="noreferrer"><img alt="bracket" src={bracket} /></a></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td><span title="8/22/05 - 11/28/05">4 <span className="text-xs">(Fall 2005)</span></span></td>
            <td><a className="underline decoration-dotted" href="https://web.archive.org/web/20060211172057/http://3v3.tpgleague.org/team.info.asp?id=260" target="_blank" rel="noreferrer">Victory Party</a></td>
            <td className="centerrowicon"><a href="http://files.tpgleague.org/dod3v3/s4_lower.gif" target="_blank" rel="noreferrer"><img alt="bracket" src={bracket} /></a></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>Synergy</td>
            <td className="centerrowicon"><a href="http://files.tpgleague.org/dod3v3/s4_upper.gif" target="_blank" rel="noreferrer"><img alt="bracket" src={bracket} /></a></td>
        </tr>
        <tr>
            <td><span title="4/25/05 - 7/18/05">3 <span className="text-xs">(Spring 2005)</span></span></td>
            <td><a className="underline decoration-dotted" href="https://web.archive.org/web/20060211172000/http://3v3.tpgleague.org/team.info.asp?id=132" target="_blank" rel="noreferrer">Allied Army Mixed</a></td>
            <td className="centerrowicon"><a href="http://files.tpgleague.org/dod3v3/s3_lower.gif" target="_blank" rel="noreferrer"><img alt="bracket" src={bracket} /></a></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td><a className="underline decoration-dotted" href="http://washedup.site.nfoservers.com/oldtpg/3v3.tpgleague.org/team.info.asp%40id=184.html" target="_blank" rel="noreferrer">Elite [Ass]asins</a></td>
            <td className="centerrowicon"><a href="http://files.tpgleague.org/dod3v3/s3_upper.gif" target="_blank" rel="noreferrer"><img alt="bracket" src={bracket} /></a></td>
        </tr>
        <tr>
            <td><span title="1/10/05 - 4/11/05">2 <span className="text-xs">(Winter 2005)</span></span></td>
            <td><a className="underline decoration-dotted" href="https://web.archive.org/web/20050228082108/http://3v3.tpgleague.org/team.info.asp?id=61" target="_blank" rel="noreferrer">69th Cowfantry</a></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td><a className="underline decoration-dotted" href="https://web.archive.org/web/20050224053428/http://3v3.tpgleague.org:80/team.info.asp?id=25" target="_blank" rel="noreferrer">Unsung Heroes</a></td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td><span title="9/12/04 - 12/12/04">1 <span className="text-xs">(Fall 2004)</span></span></td>
            <td><a className="underline decoration-dotted" href="https://web.archive.org/web/20050123000354/http://3v3.tpgleague.org/team.info.asp?id=25" target="_blank" rel="noreferrer">Unsung Heroes</a></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
        </tr>
        </table>
        Notes:<em className="text-sm">
        <ul>
            <li>Season 18: The season was started and ran from 9/9/13 - 9/17/13, but dissolved after 2 weeks of play and no winner was declared.</li>
            <li>Season 6:&nbsp; There was no winner of the lower division because neither team ever agreed on a time to play the match.</li>
            <li>Seasons 1-7: Links to teams on archive.org or other external sites are as close to the timeframe of the corresponding season as possible.</li>
        </ul>
        </em>

        </div>
    );
}