export default function Dod7v7PastChamps() {
    return (
        <div>

        <h3 className="ml-1 font-bold text-lg text-blue-600">TPG Day of Defeat 7v7 (5/18/03 - 12/21/03)</h3>

        <table className="pastchamps">
        <tr>
            <th>Season</th>
            <th>Lower</th>
            <th>Upper</th>
        </tr>
        <tr>
            <td><span title="5/18/13 - 9/7/13">4 <span className="text-xs">(Summer 2003)</span></span></td>
            <td><a className="underline decoration-dotted" href="https://web.archive.org/web/20030606014922/http://www.tpgleague.org:80/teams/g0.html" target="_blank" rel="noreferrer">Game0n</a></td>
            <td><a className="underline decoration-dotted" href="https://web.archive.org/web/20030917030256/http://www.tpgleague.org/teams/fu.html" target="_blank" rel="noreferrer">Fusion</a></td>
        </tr>
        <tr>
            <td><span title="9/28/03 - 12/21/03">5 <span className="text-xs">(Fall 2003)</span></span></td>
            <td>Infantry From Hell</td>
            <td><a className="underline decoration-dotted" href="https://web.archive.org/web/20030201232257/http://www.tpgleague.org:80/teams/5o.html" target="_blank" rel="noreferrer">5.o</a></td>
        </tr>
        </table>
        Notes:<em className="text-sm">
        <ul>
            <li>Season numbers corresponded with the DOD 6v6 league season numbers as they ran concurrently</li>
        </ul>
        </em>

        </div>
    );
}