import React, { useState, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useNavigate, Link  } from "react-router-dom";
import MapLink from '../Map/MapLink.js';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import EventNoteIcon from '@mui/icons-material/EventNote';

export default function TeamMatchHistory(props) {
    const [seasonTabId, setSeasonTabId] = React.useState(0);
    const [teamMatchHistory, setTeamMatchHistory] = useState(null);
    const [isMatchHistoryOpen, setIsMatchHistoryOpen] = React.useState(false);
    const [seasons, setSeasons] = useState([]);
    const teamId = parseInt(props.teamId);
    const navigate = useNavigate();

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + '/api/Team/TeamMatchHistory?teamId=' + props.teamId)
        .then(response => response.json())
        .then(data => {
            setTeamMatchHistory(data);
        });
      }, [props.teamId]);

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + '/api/Team/TeamMatchHistorySeasons?teamId=' + props.teamId)
        .then(response => response.json())
        .then(data => {
            setSeasons(data);
        });
    }, [props.teamId]);

    const onTabChange = (event, tabIndex) => {
        setSeasonTabId(tabIndex);
    };

    function ViewMatchDetails(mid) {
        navigate('/match/' + mid);
    }

    function Opponent(props) {
        const {home_tid, away_tid, away_team_name, home_team_name, forfeit_home, forfeit_away} = props.match;
        const teamWasHome = home_tid === teamId;
        const opponentTid = teamWasHome ? away_tid : home_tid;
        const teamLostByFf = (teamWasHome && forfeit_home) || (!teamWasHome && forfeit_away);
        let opponentName = teamWasHome ? away_team_name : home_team_name;
        if (opponentName) {
            opponentName = <Link className="text-blue-500 underline" to={"/team/" + opponentTid}>{opponentName}</Link>
        }
        else {
            teamLostByFf ? <em title="The scheduled opponent won by forfeit and then played a makeup match with a different opponent">Opponent Rescheduled</em> :
            opponentName = <em>Bye</em>;
        }

        return opponentName;
    }

    function Score(props) {
        const {home_tid, away_tid, win_tid, away_score, home_score, forfeit_home, forfeit_away} = props.match;
        const teamWasHome = home_tid === teamId;

        const homeScoreStyle = home_tid === teamId && away_score !== home_score ? {fontWeight: 'bold'} : {fontWeight: 'normal'};
        const awayScoreStyle = away_tid === teamId && away_score !== home_score ? {fontWeight: 'bold'} : {fontWeight: 'normal'};

        const largerScore = home_score > away_score
            ? <span style={homeScoreStyle}>{home_score}</span>
            : <span style={awayScoreStyle}>{away_score}</span>
        const smallerScore = home_score < away_score
            ? <span style={homeScoreStyle}>{home_score}</span>
            : <span style={awayScoreStyle}>{away_score}</span>

        const score = <>{largerScore} - {smallerScore}</>;

        let result;
        if ((forfeit_home && teamWasHome) || (forfeit_away && !teamWasHome)) {
        result = <span style={{color: 'red', fontWeight: 'bold'}}>Forfeit Loss</span>
        }
        else if ((forfeit_home && !teamWasHome) || (forfeit_away && teamWasHome)) {
        result = <span style={{color: 'blue', fontWeight: 'bold'}}>Forfeit Win</span>
        }
        else if (home_tid === 0 || away_tid === 0) {
            result = <em>Bye</em>
        }
        else if (teamId === win_tid) {
            result = <>{score} <span style={{color: 'blue', fontWeight: 'bold'}}>Win</span></>
        }
        else if (!win_tid && away_score === home_score) {
            result = <>{score} Tie</>
        }
        else {
            result = <>{score} <span style={{color: 'red', fontWeight: 'bold'}}>Loss</span></>
        }

        return result;
    }

    function TabPanel(props) {
        return (
            <div
            role="tabpanel"
            hidden={props.value !== props.index}
            >
            <h4 className="mt-1 max-w-2xl text-base text-gray-800" style={{padding: '10px 0 0 30px'}}>{props.seasonData.league_title} - Season {props.seasonData.season_number} ({props.seasonData.season_title})</h4>

            {props.value === props.index && (
                <Box sx={{ p: 3 }}>
                    <table>
                        <thead>
                            <tr>
                                <th align='left' style={{padding: '5px'}}>&nbsp;</th>
                                <th align='left' style={{padding: '5px'}}>Week</th>
                                <th align='left' style={{padding: '5px'}}>Map</th>
                                <th align='left' style={{padding: '5px'}}>Opponent</th>
                                <th align='left' style={{padding: '5px'}}>Score</th>
                            </tr>
                        </thead>
                        <tbody>
                        {teamMatchHistory.filter(tmh => tmh.sid === props.seasonData.sid).map((m) =>
                            <tr key={m.mid}>
                                <td style={{padding: '5px'}}>
                                    <IconButton aria-label="View Match Details" onClick={() => ViewMatchDetails(m.mid)}>
                                        <EventNoteIcon />
                                    </IconButton>
                                </td>
                                <td style={{padding: '5px'}}>{m.stg_short_desc}</td>
                                <td style={{padding: '5px'}}><MapLink mapName={m.map_title} mapUrl={m.map_url} /></td>
                                <td style={{padding: '5px'}}><Opponent match={m} /></td>
                                <td style={{padding: '5px'}}><Score match={m} /></td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </Box>
            )}
            </div>
        );
    }

    return (
        <div style={{marginBottom: "15px"}}>
        {(teamMatchHistory === null) ? <h3 style={{margin: "15px 0px 0px 15px"}}>Loading match history...</h3>
        : (teamMatchHistory.length === 0) ? <h3 style={{margin: "15px 0px 0px 15px"}}>No match history found for this team.</h3>
        :
        <>
        <div className="px-4 sm:px-6">
            <h3 className="text-lg font-bold leading-6 text-blue-600">
                <IconButton onClick={() => setIsMatchHistoryOpen(!isMatchHistoryOpen)} aria-label="expand match history" size="small">
                    {isMatchHistoryOpen ? <KeyboardArrowDownIcon />  : <KeyboardArrowRightIcon />}
                </IconButton>
                <span className="cursor-pointer" onClick={() => setIsMatchHistoryOpen(!isMatchHistoryOpen)}>Team Match History</span>
            </h3>
        </div>
        <Collapse in={isMatchHistoryOpen} timeout={0} unmountOnExit>
        <div style={{margin: "15px 0px 0px 15px"}}>
            <Box sx={{bgcolor: 'background.paper' }}>
                <Tabs
                value={seasonTabId}
                onChange={onTabChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="matches by season"
                >
                    {seasons.map((s, index) =>
                        <Tab key={s.sid} value={index} label={s.season_title} />
                    )}
                </Tabs>
            </Box>
            {seasons.map((s, index) =>
                <TabPanel key={"tb" + s.sid} value={seasonTabId} index={index} seasonData={s} />
            )}
        </div>
        </Collapse>
        </>
        }
        </div>
    );
}