import * as React from 'react';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import PersonIcon from '@mui/icons-material/Person';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useNavigate } from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1976d2",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function UserSearchResults(props) {
  const navigate = useNavigate();

  function ViewPlayerDetails(uid) {
    navigate("/user/" + uid);
  }

  return (
    <>
      {
      props.searchResults.length === 0
        ? <h3 style={{margin: "-30px 0px 0px 15px"}}>No Results Found.</h3>
        :
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} size="small" aria-label="user search results">
          <TableHead>
            <TableRow>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell>Handle</StyledTableCell>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Steam Profile</StyledTableCell>
              <StyledTableCell>Roster Handles</StyledTableCell>
              <StyledTableCell>Game IDs</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.searchResults.map((row) => (
              <StyledTableRow key={row.uid}>
                <StyledTableCell component="th" scope="row">
                <IconButton aria-label="View Player Details" onClick={() => ViewPlayerDetails(row.uid)}>
                  <PersonIcon />
                </IconButton>
                </StyledTableCell>
                <StyledTableCell>{row.handle}</StyledTableCell>
                <StyledTableCell>{row.fullname}</StyledTableCell>
                <StyledTableCell>
                  {(row.steam_profile_url.startsWith("http://steamcommunity.com/id")
                    || row.steam_profile_url.startsWith("http://steamcommunity.com/profiles")) ?
                      <a className="text-blue-500 underline" href={row.steam_profile_url}>
                      {row.steam_profile_url.replace("http://steamcommunity.com/id", "")
                      .replace("http://steamcommunity.com/profiles", "")
                      .replaceAll("/", "")}
                    </a>
                    :<span>{row.steam_profile_url}</span>}
                </StyledTableCell>
                <StyledTableCell>{row.rosterHandles}</StyledTableCell>
                <StyledTableCell>{row.rosterGameIds}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      }
    </>
  );
}