import { useState } from 'react';
import UserSearchForm from './UserSearchForm.js';
import UserSearchResults from './UserSearchResults.js';

export default function UserSearch() {
  const [searchResults, setSearchResults] = useState();

  return (
    <>
        <div style={{margin: "75px 0px -10px 45px"}}>
          <h3 className="text-lg font-bold leading-6 text-blue-600">
            User Search
          </h3>
          Your search text will search the following: name, handle, username, email, roster names, steam profile name, user avatar URL, user comments, and game IDs
        </div>
        {/* TODO: Hold value on back button */}

        <UserSearchForm onQuery={setSearchResults} />
        <div style={{margin: "25px"}}>
          {searchResults !== undefined &&
          (searchResults === "searching"
           ? <h3 style={{margin: "-30px 0px 0px 15px"}}>Search in progress...</h3>
           : <UserSearchResults searchResults={searchResults} />)}
        </div>
    </>
  );
}