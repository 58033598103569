import * as React from 'react';
import  { useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Dod3v3PastChamps from "./Dod3v3PastChamps";
import Dod6v6PastChamps from "./Dod6v6PastChamps";
import Dod7v7PastChamps from "./Dod7v7PastChamps";
import DodSource3v3PastChamps from "./DodSource3v3PastChamps";
import DodSource6v6PastChamps from "./DodSource6v6PastChamps";
import CsGoPastChamps from './CsGoPastChamps';
import HawkenPastChamps from './HawkenPastChamps';
import PastChampsExternalLinks from './PastChampsExternalLinks';
import DodClassicPastChamps from './DodClassicPastChamps';
import { useSearchParams, useNavigate, createSearchParams } from "react-router-dom";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other}>
            {value === index && (
                <Box sx={{ pl: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

export default function PastChampions() {
    const [value, setValue] = React.useState(0);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        switch (searchParams.get("league")) {
            case "dod6":
            case "dod6v6":
            case "0":
                setValue(0);
                break;
            case "dod3":
            case "dod3v3":
            case "1":
                setValue(1);
                break;
            case "dod7":
            case "dod7v7":
            case "2":
                setValue(2);
                break;
            case "dodc":
            case "dodclassic":
            case "classic":
            case "3":
                setValue(3);
                break;
            case "dods6":
            case "dods6v6":
            case "dodsource6":
            case "dodsource6v6":
            case "4":
                setValue(4);
                break;
            case "dods3":
            case "dods3v3":
            case "dodsource3":
            case "dodsource3v3":
            case "5":
                setValue(5);
                break;
            case "csgo":
            case "6":
                setValue(6);
                break;
            case "hwk":
            case "hawken":
            case "7":
                setValue(7);
                break;
            default:
                setValue(8);
                break;
        }
    }, [searchParams]);

    function GetShortLeagueName(index) {
        let shortLeagueName;

        switch (index)
        {
            case 0:
                shortLeagueName = "dod6";
                break;
            case 1:
                shortLeagueName = "dod3";
                break;
            case 2:
                shortLeagueName = "dod7";
                break;
            case 3:
                shortLeagueName = "dodc";
                break;
            case 4:
                shortLeagueName = "dods6";
                break;
            case 5:
                shortLeagueName = "dods3";
                break;
            case 6:
                shortLeagueName = "csgo";
                break;
            case 7:
                shortLeagueName = "hwk";
                break;
            default:
                shortLeagueName = "external";
                break;
        }

        return shortLeagueName;
    }

    const handleChange = (event, newValue) => {
        const shortLeagueName = GetShortLeagueName(newValue);

        navigate({
            search: createSearchParams({
                league: shortLeagueName
            }).toString()
        });

        setValue(newValue);
    };

    return (
        <div style={{marginLeft: "10px"}}>

        <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}>
            <Tabs orientation="vertical" variant="scrollable" value={value} onChange={handleChange} sx={{ borderRight: 1, borderColor: 'divider' }}>
                <Tab label="DOD 6v6" />
                <Tab label="DOD 3v3" />
                <Tab label="DOD 7v7" />
                <Tab label="DOD Classic" />
                <Tab label="DOD:S 6v6" />
                <Tab label="DOD:S 3v3" />
                <Tab label="CS:GO" />
                <Tab label="Hawken" />
                <Tab label="External Links" />
            </Tabs>
            <TabPanel value={value} label="DOD 6v6" index={0}>
                <Dod6v6PastChamps />
            </TabPanel>
            <TabPanel value={value} label="DOD 3v3" index={1}>
                <Dod3v3PastChamps />
            </TabPanel>
            <TabPanel value={value} label="DOD 7v7" index={2}>
                <Dod7v7PastChamps />
            </TabPanel>
            <TabPanel value={value} label="DOD Classic" index={3}>
                <DodClassicPastChamps />
            </TabPanel>
            <TabPanel value={value} label="DOD:S 6v6" index={4}>
                <DodSource6v6PastChamps />
            </TabPanel>
            <TabPanel value={value} label="DOD:S 3v3" index={5}>
                <DodSource3v3PastChamps />
            </TabPanel>
            <TabPanel value={value} label="CS:GO" index={6}>
                <CsGoPastChamps />
            </TabPanel>
            <TabPanel value={value} label="Hawken" index={7}>
                <HawkenPastChamps />
            </TabPanel>
            <TabPanel value={value} label="External Links" index={8}>
                <PastChampsExternalLinks />
            </TabPanel>
        </Box>

        </div>
    );
}