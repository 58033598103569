import { Link  } from "react-router-dom";

export default function DodClassicPastChamps() {
    return (
        <div>

        <h3 className="ml-1 font-bold text-lg text-blue-600">TPG Day of Defeat Classic (7/11/12 - 10/3/12)</h3>

        {/*
            - The classic league was changed to TPG Nations after the fact lid = 13, sid = 47
            - sid 50 looks like a normal league what was this?
            - sid 56 was never played?
            - sid 73 was the TPG Nations tournament
        */}
        <table className="pastchamps">
        <tr>
            <th>Season</th>
            <th>Open</th>
        </tr>
        <tr>
            <td><span title="11/7/12 - 1/16/13">2 <span className="text-xs">(Fall 2012)</span></span></td>
            <td><Link className="underline decoration-dotted" to={"/team/1785"}>Can't Find Six</Link></td>
        </tr>
        <tr>
            <td><span title="7/11/12 - 10/3/12">1 <span className="text-xs">(Summer 2012)</span></span></td>
            <td><Link className="underline decoration-dotted" to={"/team/1785"}>85th Infantry Division</Link></td>
        </tr>
        </table>
        Notes:<em className="text-sm">
        <ul>
            <li>Season 2:&nbsp;&nbsp;&nbsp; The league was known as Wednesday Night DOD and incorporated some non-classic maps</li>
            <li>Seasons 1-2: The DOD Classic League spot in the database was reused to create TPG Nations, so the "Summer 2012" and "Fall 2012" seasons listed for TPG Nations were actually the Classic league.</li>
        </ul>
        </em>

        </div>
    );
}