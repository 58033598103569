import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

function DivisionTitle(props) {
	return <h3 className="text-blue-500 font-bold">{props.row.division_title}</h3>;
}

function StandingsRow(props) {
	const row = props.row;

	return <table>
		<thead>
			<tr>
				<th title="Team Name">Team</th>
				<th title="Team Tag">Tag</th>
				<th title="Wins">W</th>
				<th title="Losses">L</th>
				<th title="Ties">T</th>
				<th title="Forfeit Wins">FFW</th>
				<th title="Forfeit Losses">FFL</th>
				<th title="Points For">PF</th>
				<th title="Points Against">PA</th>
				<th title="Points Difference">PD</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td><a href="{$lgname}/team/{$team.tid}/">{row.name}</a></td>
				<td>{row.tag}</td>
				<td>{row.matches_played ? row.wins : <>-</>}</td>
				<td>{row.matches_played ? row.losses : <>-</>}</td>
				<td>{row.matches_played ? row.ties : <>-</>}</td>
				<td>{row.forfeit_wins   ? row.forfeit_wins : <>-</>}</td>
				<td>{row.forfeit_losses ? row.forfeit_losses : <>-</>}</td>
				<td>{row.matches_played ? row.points_for : <>-</>}</td>
				<td>{row.matches_played ? row.points_against : <>-</>}</td>
				<td>{row.matches_played ? row.points_difference : <>-</>}</td>
			</tr>
		</tbody>
	</table>;
}

export default function Standings() {
    const [standings, setStandings] = useState(null);
	let params = useParams();
	let previousDivId = 0;

    useEffect(() => {
		fetch(process.env.REACT_APP_API_URL + '/api/Standings/Get?seasonId=' + params.sid)
		.then(response => response.json())
		.then(data => {
			setStandings(data);
		});
    }, [params.sid]);

    return (
        <>
        {(standings === null) ? <h3 style={{marginLeft: "15px"}}>Loading standings...</h3>
        : (standings.sid === 0) ? <h3 style={{marginLeft: "15px"}}>The league and/or season provided are either invalid or there is no data avialable for the given season.</h3>
        : <div style={{marginLeft: "15px"}}>

		{/* TODO: can I reduce to divid and then loop through and filter a map by divid? */}
		<p>SID: {params.sid}</p><br />
		{standings.map((row, index) => {
			if(row.divid !== previousDivId) {
				previousDivId = row.divid
				return <div key={index}><DivisionTitle row={row} /><StandingsRow row={row} /></div>;
			} else {
				return <div key={index}><StandingsRow row={row} /></div>;
			}
		})}

        </div>
        }
        </>
      );
}