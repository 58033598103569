import { Link } from "react-router-dom";
import bracket from '../../images/bracket.png';
import camera from '../../images/camera.png';
import demo from '../../images/demo.png';

export default function Dod6v6PastChamps() {
    return (
        <div className="mb-10">

        <h3 className="ml-1 font-bold text-lg text-blue-600">TPG Day of Defeat 6v6 - North America (9/29/02 - 7/27/14)</h3>
        <table className="pastchamps">
        <tr>
            <th title="Hover over the season number for the full date range of the season">Season</th>
            <th colspan="2">Lower/Open</th>
            <th colspan="2">Intermediate</th>
            <th colspan="2">Middle/Main</th>
            <th colspan="2">Upper/Invite</th>
        </tr>
        <tr>
            <td><span data-sid="74" data-note="listed as season 35 in database" title="04/27/14 - 07/27/14">34</span></td>
            <td><Link className="underline decoration-dotted" to={"/team/2115/"}>Infinite</Link></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td><Link className="underline decoration-dotted" to={"/team/1302/"}>THUNDER</Link></td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td><span data-sid="69" title="12/01/13 - 02/24/14">33</span></td>
            <td><Link className="underline decoration-dotted" to={"/team/1986/"}>OneFiveOne</Link></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td><Link className="underline decoration-dotted" to={"/team/1762/"}>38th</Link></td>
            <td>&nbsp;</td>
            <td><Link className="underline decoration-dotted" to={"/team/2003/"}>g.skiLL</Link></td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td><span data-sid="63" title="07/28/13 - 11/24/13">32</span></td>
            <td><Link className="underline decoration-dotted" to={"/team/2059/"}>inXtremis</Link></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td><Link className="underline decoration-dotted" to={"/team/1816/"}>3xtreme Humiliation</Link></td>
            <td>&nbsp;</td>
            <td><Link className="underline decoration-dotted" to={"/team/2003/"}>g.skiLL</Link></td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td><span data-sid="57" title="04/01/13 - 07/21/13">31</span></td>
            <td><Link className="underline decoration-dotted" to={"/team/679/"}>Merrill's Marauders</Link></td>
            <td>&nbsp;</td>
            <td><Link className="underline decoration-dotted" to={"/team/2008/"}>KLIQ</Link></td>
            <td>&nbsp;</td>
            <td><Link className="underline decoration-dotted" to={"/team/1902/"}>92 Dream Team</Link></td>
            <td>&nbsp;</td>
            <td><Link className="underline decoration-dotted" to={"/team/1302/"}>THUNDER</Link></td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td><span data-sid="54" title="12/09/12 - 03/25/13">30</span></td>
            <td><Link className="underline decoration-dotted" to={"/team/1939/"}>Defenders of Zion</Link></td>
            <td>&nbsp;</td>
            <td><Link className="underline decoration-dotted" to={"/team/1869/"}>By Invite Only</Link></td>
            <td>&nbsp;</td>
            <td><Link className="underline decoration-dotted" to={"/team/1902/"}>92 Dream Team</Link></td>
            <td>&nbsp;</td>
            <td><Link className="underline decoration-dotted" to={"/team/1302/"}>Five And A Half Men</Link></td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td><span data-sid="48" title="8/19/12 - 11/21/12">29</span></td>
            <td><Link className="underline decoration-dotted" to={"/team/1817/"}>OneFiveOne</Link></td>
            <td>&nbsp;</td>
            <td><Link className="underline decoration-dotted" to={"/team/1431/"}>Money over B1tch3s</Link></td>
            <td>&nbsp;</td>
            <td><Link className="underline decoration-dotted" to={"/team/1753/"}>hard to kill</Link></td>
            <td>&nbsp;</td>
            <td><Link className="underline decoration-dotted" to={"/team/1302/"}>Five And A Half Men</Link></td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td><span data-sid="46" title="4/2/12 - 8/5/12">28</span></td>
            <td><Link className="underline decoration-dotted" to={"/team/1603/"}>505th PIR</Link></td>
            <td>&nbsp;</td>
            <td><Link className="underline decoration-dotted" to={"/team/1051/"}>MVPz</Link></td>
            <td>&nbsp;</td>
            <td><Link className="underline decoration-dotted" to={"/team/1726/"}>Shut up and Party</Link></td>
            <td>&nbsp;</td>
            <td><Link className="underline decoration-dotted" to={"/team/1725/"}>heart-shaped box</Link></td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td><span data-sid="41" title="12/11/11 - 4/1/12">27</span></td>
            <td><Link className="underline decoration-dotted" to={"/team/1674/"}>Kevlar's Widowers</Link></td>
            <td>&nbsp;</td>
            <td><Link className="underline decoration-dotted" to={"/team/1148/"}>Kevlar Soul</Link></td>
            <td>&nbsp;</td>
            <td><Link className="underline decoration-dotted" to={"/team/1403/"}>3xtreme Humiliation</Link></td>
            <td>&nbsp;</td>
            <td><Link className="underline decoration-dotted" to={"/team/1619/"}>G.SKILL</Link></td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td><span data-sid="35" title="8/14/11 - 11/6/11">26</span></td>
            <td><Link className="underline decoration-dotted" to={"/team/1497/"}>codename:killers</Link></td>
            <td className="centerrowicon"><a href="http://files.tpgleague.org/dod6v6/OpenBracket.jpg" target="_blank" rel="noreferrer"><img alt="bracket" src={bracket} /></a></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td><Link className="underline decoration-dotted" to={"/team/1380/"}>Mindflow</Link></td>
            <td className="centerrowicon"><a href="http://files.tpgleague.org/dod6v6/MainBracket.jpg" target="_blank" rel="noreferrer"><img alt="bracket" src={bracket} /></a></td>
            <td><Link className="underline decoration-dotted" to={"/team/1302/"}>THUNDER</Link></td>
            <td className="centerrowicon"><a href="http://files.tpgleague.org/dod6v6/InviteBracket.jpg" target="_blank" rel="noreferrer"><img alt="bracket" src={bracket} /></a></td>
        </tr>
        <tr>
            <td><span data-sid="32" title="4/3/11 - 7/10/11">25</span></td>
            <td><Link className="underline decoration-dotted" to={"/team/1380/"}>Heavy Hitters</Link></td>
            <td className="centerrowicon"><a href="http://files.tpgleague.org/dod6v6/s25_lower_bracket.jpg" target="_blank" rel="noreferrer"><img alt="bracket" src={bracket} /></a><a title="HLTV demos of finals match" href="http://files.tpgleague.org/dod6v6/tpg-25-lower-finals-1080p-vs-hh.zip"> <img alt="film reel" src={demo} /></a></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td><Link className="underline decoration-dotted" to={"/team/1302/"}>THUNDER</Link></td>
            <td className="centerrowicon"><a href="http://files.tpgleague.org/dod6v6/s25_upper_bracket.jpg" target="_blank" rel="noreferrer"><img alt="bracket" src={bracket} /></a></td>
        </tr>
        <tr>
            <td><span data-sid="30" title="11/7/10 - 3/13/11">24</span></td>
            <td><Link className="underline decoration-dotted" to={"/team/1262/"}>Shut Up and Party</Link></td>
            <td className="centerrowicon"><a href="http://files.tpgleague.org/s24usalplayoffs.jpg" target="_blank" rel="noreferrer"><img alt="bracket" src={bracket} /></a></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td><Link className="underline decoration-dotted" to={"/team/1166/"}>iwa</Link></td>
            <td className="centerrowicon"><a href="http://files.tpgleague.org/s24usauplayoffs.jpg" target="_blank" rel="noreferrer"><img alt="bracket" src={bracket} /></a></td>
        </tr>
        <tr>
            <td><span data-sid="27" title="6/27/10 - 10/17/10">23</span></td>
            <td><Link className="underline decoration-dotted" to={"/team/1191/"}>Donkey PunchN Krew</Link></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td><Link className="underline decoration-dotted" to={"/team/1166/"}>iwa</Link></td>
            <td className="centerrowicon"><a title="End round screenshot for round 1" href="http://files.tpgleague.org/s23_finals_r1.jpg"><img alt="camera" src={camera} /></a> <a title="End round screenshot for round 2" href="http://files.tpgleague.org/s23_finals_r2.jpg"><img alt="camera" src={camera} /></a></td>
        </tr>
        <tr>
            <td><span data-sid="26" title="2/15/09 - 6/5/09">22</span></td>
            <td><Link className="underline decoration-dotted" to={"/team/1149/"}>This Romantic Tragedy</Link></td>
            <td className="centerrowicon"><a href="http://files.tpgleague.org/dod6v6/s22_lower_playoffs.gif" target="_blank" rel="noreferrer"><img alt="bracket" src={bracket} /></a></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td><Link className="underline decoration-dotted" to={"/team/29/"}>[B]add</Link></td>
            <td className="centerrowicon"><a href="http://files.tpgleague.org/dod6v6/s22_upper_playoffs.gif" target="_blank" rel="noreferrer"><img alt="bracket" src={bracket} /></a></td>
        </tr>
        <tr>
            <td><span data-sid="24" title="2/15/09 - 6/5/09">21</span></td>
            <td><Link className="underline decoration-dotted" to={"/team/779/"}>Natural Born Killers</Link></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td><Link className="underline decoration-dotted" to={"/team/772/"}>Man the Periscope</Link></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td><span data-sid="22" title="9/7/08 - 12/21/08">20</span></td>
            <td><Link className="underline decoration-dotted" to={"/team/709/"}>Game Spy Rangers</Link></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td><Link className="underline decoration-dotted" to={"/team/976/"}>Brittany's Vent Crew</Link></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td><span data-sid="19" title="5/18/08 - 9/17/08">19</span></td>
            <td><Link className="underline decoration-dotted" to={"/team/889/"}>DeucesWild</Link></td>
            <td className="centerrowicon"><a href="http://files.tpgleague.org/dod6v6/lowerbracket19.jpg" target="_blank" rel="noreferrer"><img alt="bracket" src={bracket} /></a></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td><Link className="underline decoration-dotted" to={"/team/772/"}>Man the Periscope</Link></td>
            <td className="centerrowicon"><a href="http://files.tpgleague.org/dod6v6/middlebracket19.jpg" target="_blank" rel="noreferrer"><img alt="bracket" src={bracket} /></a></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td><span data-sid="17" title="1/20/08 - 4/27/08">18</span></td>
            <td><Link className="underline decoration-dotted" to={"/team/773/"}>With class</Link></td>
            <td className="centerrowicon"><a href="http://files.tpgleague.org/dod6v6/lowerbracket.jpg" target="_blank" rel="noreferrer"><img alt="bracket" src={bracket} /></a></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td><Link className="underline decoration-dotted" to={"/team/760/"}>agaXando</Link></td>
            <td className="centerrowicon"><a href="http://files.tpgleague.org/dod6v6/middlebracket.jpg" target="_blank" rel="noreferrer"><img alt="bracket" src={bracket} /></a></td>
            <td><Link className="underline decoration-dotted" to={"/team/684/"}>Nascidos Pro Game</Link></td>
            <td className="centerrowicon"><a href="http://files.tpgleague.org/dod6v6/upperbracket.jpg" target="_blank" rel="noreferrer"><img alt="bracket" src={bracket} /></a></td>
        </tr>
        <tr>
            <td><span data-sid="14" title="9/9/07 - 12/9/07">17</span></td>
            <td><Link className="underline decoration-dotted" to={"/team/684/"}>Nascidos Pro Game</Link></td>
            <td className="centerrowicon"><a href="http://files.tpgleague.org/dod6v6/tpg17lower.GIF" target="_blank" rel="noreferrer"><img alt="bracket" src={bracket} /></a></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td><Link className="underline decoration-dotted" to={"/team/559/"}>Dynasty</Link></td>
            <td className="centerrowicon"><a href="http://files.tpgleague.org/dod6v6/tpg17middle.GIF" target="_blank" rel="noreferrer"><img alt="bracket" src={bracket} /></a></td>
            <td><Link className="underline decoration-dotted" to={"/team/640/"}>THUNDER</Link></td>
            <td className="centerrowicon"><a href="http://files.tpgleague.org/dod6v6/tpg17Upper.GIF" target="_blank" rel="noreferrer"><img alt="bracket" src={bracket} /></a></td>
        </tr>
        <tr>
            <td><span data-sid="10" title="4/1/07 - 8/8/07">16</span></td>
            <td><Link className="underline decoration-dotted" to={"/team/371/"}>Fallen Soldiers Reborn</Link></td>
            <td className="centerrowicon"><a href="http://files.tpgleague.org/dod6v6/s16_lower.png" target="_blank" rel="noreferrer"><img alt="bracket" src={bracket} /></a></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td><Link className="underline decoration-dotted" to={"/team/148/"}>Xtremely Tasty Cupcakes</Link></td>
            <td className="centerrowicon"><a href="http://files.tpgleague.org/dod6v6/s16_middle.png" target="_blank" rel="noreferrer"><img alt="bracket" src={bracket} /></a></td>
            <td><Link className="underline decoration-dotted" to={"/team/79/"}>Super!</Link></td>
            <td className="centerrowicon"><a href="http://files.tpgleague.org/dod6v6/s16_upper.png" target="_blank" rel="noreferrer"><img alt="bracket" src={bracket} /></a></td>
        </tr>
        <tr>
            <td><span data-sid="2" title="12-10-06 - 3/25/07">15</span></td>
            <td><Link className="underline decoration-dotted" to={"/team/196/"}>Not Impressed</Link></td>
            <td className="centerrowicon"><a href="http://files.tpgleague.org/dod6v6/s15p-lower.gif" target="_blank" rel="noreferrer"><img alt="bracket" src={bracket} /></a></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td><Link className="underline decoration-dotted" to={"/team/204/"}>Markomania</Link></td>
            <td className="centerrowicon"><a href="http://files.tpgleague.org/dod6v6/s15p-middle.gif" target="_blank" rel="noreferrer"><img alt="bracket" src={bracket} /></a></td>
            <td><Link className="underline decoration-dotted" to={"/team/218/"}>Habibs Money Crew</Link></td>
            <td className="centerrowicon"><a href="http://files.tpgleague.org/dod6v6/s15p-upper.gif" target="_blank" rel="noreferrer"><img alt="bracket" src={bracket} /></a></td>
        </tr>
        <tr>
            <td><span data-sid="old site" title="7/23/06 - 11/12/06">14</span></td>
            <td><a className="underline decoration-dotted" href="http://washedup.site.nfoservers.com/oldtpg/dod.tpgleague.org/team.info.asp%40id=934.html" target="_blank" rel="noreferrer">RancH!</a></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td><a className="underline decoration-dotted" href="http://washedup.site.nfoservers.com/oldtpg/dod.tpgleague.org/team.info.asp%40id=962.html" target="_blank" rel="noreferrer">DoppleGanger Syndicate</a></td>
            <td>&nbsp;</td>
            <td><a className="underline decoration-dotted" href="http://washedup.site.nfoservers.com/oldtpg/dod.tpgleague.org/team.info.asp%40id=754.html" target="_blank" rel="noreferrer">ARMY</a></td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td><span data-sid="old site" title="3/12/06 - 7/2/06">13</span></td>
            <td><Link className="underline decoration-dotted" to={"/team/461/"}>Victory Party</Link></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td><a className="underline decoration-dotted" href="https://web.archive.org/web/20060415030422/http://dod.tpgleague.org/team.info.asp?id=859" target="_blank" rel="noreferrer">Cal Teriblez</a></td>
            <td>&nbsp;</td>
            <td><a className="underline decoration-dotted" href="https://web.archive.org/web/20060415025720/http://dod.tpgleague.org/team.info.asp?id=754" target="_blank" rel="noreferrer">ARMY</a></td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td><span data-sid="old site" title="1/8/06 - 3/4/06">12</span></td>
            <td><a className="underline decoration-dotted" href="http://washedup.site.nfoservers.com/oldtpg/dod.tpgleague.org/team.info.asp%40id=759.html" target="_blank" rel="noreferrer">revamped</a></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td><span data-sid="old site" title="7/24/05 - 10/16/05">11</span></td>
            <td>General Infantry</td>
            <td>&nbsp;</td>
            <td>Surprise In A Box</td>
            <td>&nbsp;</td>
            <td><a className="underline decoration-dotted" href="https://web.archive.org/web/20050216090627/http://dod.tpgleague.org/team.info.asp?id=264" target="_blank" rel="noreferrer">Rise of Legends</a></td>
            <td>&nbsp;</td>
            <td><a className="underline decoration-dotted" href="https://web.archive.org/web/20050220073008/http://dod.tpgleague.org/team.info.asp?id=347" target="_blank" rel="noreferrer">skull</a></td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td><span data-sid="old site" title="4/17/05 - 7/3/05">10</span></td>
            <td><a className="underline decoration-dotted" href="https://web.archive.org/web/20050223033347/http://dod.tpgleague.org/team.info.asp?id=377" target="_blank" rel="noreferrer">Emerald Force</a></td>
            <td>&nbsp;</td>
            <td>Ghost</td>
            <td>&nbsp;</td>
            <td><a className="underline decoration-dotted" href="https://web.archive.org/web/20050216090627/http://dod.tpgleague.org/team.info.asp?id=264" target="_blank" rel="noreferrer">Rise of Legends</a></td>
            <td>&nbsp;</td>
            <td>Infinity Gaming</td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td><span data-sid="old site" title="1/2/05 - 4/10/05">9</span></td>
            <td><a className="underline decoration-dotted" href="https://web.archive.org/web/20050222071034/http://dod.tpgleague.org/team.info.asp?id=367" target="_blank" rel="noreferrer">Something With An A</a></td>
            <td>&nbsp;</td>
            <td><a className="underline decoration-dotted" href="https://web.archive.org/web/20050215134325/http://dod.tpgleague.org/team.info.asp?id=248" target="_blank" rel="noreferrer">Quebec Patriots</a></td>
            <td>&nbsp;</td>
            <td><a className="underline decoration-dotted" href="https://web.archive.org/web/20050221070353/http://dod.tpgleague.org/team.info.asp?id=58" target="_blank" rel="noreferrer">Detachment Alpha</a></td>
            <td>&nbsp;</td>
            <td><a className="underline decoration-dotted" href="https://web.archive.org/web/20050207012539/http://dod.tpgleague.org/team.info.asp?id=163" target="_blank" rel="noreferrer">Inevitable Demise</a></td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td><span data-sid="old site" title="9/12/04 - 12/12/04">8</span></td>
            <td><a className="underline decoration-dotted" href="https://web.archive.org/web/20041227162609/http://dod.tpgleague.org:80/team.info.asp?id=199" target="_blank" rel="noreferrer">Pub Extreme Gamers</a></td>
            <td>&nbsp;</td>
            <td><a className="underline decoration-dotted" href="https://web.archive.org/web/20050115193536/http://dod.tpgleague.org/team.info.asp?id=72" target="_blank" rel="noreferrer">Victory Party</a></td>
            <td>&nbsp;</td>
            <td><a className="underline decoration-dotted" href="https://web.archive.org/web/20041209093554/http://dod.tpgleague.org:80/team.info.asp?id=101" target="_blank" rel="noreferrer">Itchy Trigger Finger</a></td>
            <td>&nbsp;</td>
            <td><a className="underline decoration-dotted" href="https://web.archive.org/web/20041027220914/http://dod.tpgleague.org:80/team.info.asp?id=70" target="_blank" rel="noreferrer">Anti-Virus</a></td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td><span data-sid="old site" title="5/16/04 - 8/22/04 ">7</span></td>
            <td><a className="underline decoration-dotted" href="https://web.archive.org/web/20040724232215/http://dod.tpgleague.org:80/team.info.asp?id=163" target="_blank" rel="noreferrer">GIMP</a></td>
            <td className="centerrowicon"><a href="https://www.bracketmaker.com/tmenu.cfm?tid=182656&tclass=Lower%20Division" target="_blank" rel="noreferrer"><img alt="bracket" src={bracket} /></a></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td><a className="underline decoration-dotted" href="https://web.archive.org/web/20041106222011/http://dod.tpgleague.org/team.info.asp?id=81" target="_blank" rel="noreferrer">DerStuhl</a></td>
            <td className="centerrowicon"><a href="https://www.bracketmaker.com/tmenu.cfm?tid=182656&tclass=Middle%20Divsion" target="_blank" rel="noreferrer"><img alt="bracket" src={bracket} /></a></td>
            <td><a className="underline decoration-dotted" href="https://web.archive.org/web/20040813101856/http://dod.tpgleague.org:80/team.info.asp?id=119" target="_blank" rel="noreferrer">aCtion</a></td>
            <td className="centerrowicon"><a href="https://www.bracketmaker.com/tmenu.cfm?tid=182656&tclass=Upper%20Division" target="_blank" rel="noreferrer"><img alt="bracket" src={bracket} /></a></td>
        </tr>
        <tr>
            <td><span data-sid="old site" title="1/18/04 - 4/25/04 ">6</span></td>
            <td>Killers In The Woods</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td><a className="underline decoration-dotted" href="https://web.archive.org/web/20041021230454/http://dod.tpgleague.org:80/team.info.asp?id=56" target="_blank" rel="noreferrer">Fx</a></td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td><span data-sid="old site" title="9/28/03 - 12/21/03">5</span></td>
            <td><a className="underline decoration-dotted" href="https://web.archive.org/web/20030806202357/http://www.tpgleague.org/teams/av.html" target="_blank" rel="noreferrer">Anti-Virus</a></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>Dirty Ghetto F**ks</td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td><span data-sid="old site" title="5/18/03 - 9/7/03">4</span></td>
            <td><a className="underline decoration-dotted" href="https://web.archive.org/web/20030806195539/http://www.tpgleague.org/teams/36th.html" target="_blank" rel="noreferrer">36th Infantry Division</a></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td><a className="underline decoration-dotted" href="https://web.archive.org/web/20030413052644/http://www.tpgleague.org:80/teams/fx.html" target="_blank" rel="noreferrer">Fx</a></td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td><span data-sid="old site" title="2/2/03 - 3/23/03">3</span></td>
            <td><a className="underline decoration-dotted" href="https://web.archive.org/web/20030413051121/http://www.tpgleague.org:80/teams/bs.html" target="_blank" rel="noreferrer">Blitzkrieg Squad</a></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td><a className="underline decoration-dotted" href="https://web.archive.org/web/20030413054128/http://www.tpgleague.org:80/teams/pokemon.html" target="_blank" rel="noreferrer">Pokemon</a></td>
            <td>&nbsp;</td>
            <td><a className="underline decoration-dotted" href="https://web.archive.org/web/20030201232257/http://www.tpgleague.org:80/teams/5o.html" target="_blank" rel="noreferrer">5.o</a></td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td><span data-sid="old site" title="10/17/02 - 1/5/03">2</span></td>
            <td><a className="underline decoration-dotted" href="https://web.archive.org/web/20030413052644/http://www.tpgleague.org:80/teams/fx.html" target="_blank" rel="noreferrer">Fx</a></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td style={{textAlign: 'right'}} colspan="2"><i>East Upper:</i></td>
            <td><a className="underline decoration-dotted" href="https://web.archive.org/web/20030518225150/http://www.tpgleague.org:80/teams/ul.html" target="_blank" rel="noreferrer">Urban Legends</a></td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td style={{textAlign: 'right'}} colspan="2"><i>West Upper:</i></td>
            <td><a className="underline decoration-dotted" href="https://web.archive.org/web/20030104214341/http://www.tpgleague.org/teams/qgb-dod.html" target="_blank" rel="noreferrer">QGB Rage</a></td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td><span data-sid="old site" title="9/29/02 - 10/10/02">1</span></td>
            <td><i>None Declared</i></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
        </tr>
        </table>
        Notes:<em className="text-sm">
        <ul>
            <li>Season 34:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Seaon 34 was listed as "Season 35" on the site and in the database, because season 34 was used a tournament. The league news referred to this as season 34 though and not season 35.</li>
            <li>Season 21:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; In the Middle division Man the Periscope won by the match being overturned since Evil Geniuses played with a banned player.</li>
            <li>Seasons 1-15: Links to teams on archive.org or other external sites are as close to the timeframe of the corresponding season as possible.</li>
            <li>Season 1:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The finals match was never played due to a scheduling dispute, so no winner was declared.</li>
        </ul>
        </em>

        <br />
        <h3 className="ml-1 font-bold text-lg text-blue-600">TPG Day of Defeat 6v6 - South America (2/15/09 - 7/10/11)</h3>
        <table className="pastchamps">
        <tr>
            <th title="Hover over the season number for the full date range of the season">Season</th>
            <th colspan="2">Open</th>
        </tr>
        <tr>
            <td><span data-sid="32" title="4/3/11 - 7/10/11">25</span></td>
            <td><Link className="underline decoration-dotted" to={"/team/965/"}>DreamWorks</Link></td>
            <td className="centerrowicon"><a href="http://files.tpgleague.org/dod6v6/s25_sa_bracket.jpg" target="_blank" rel="noreferrer"><img alt="bracket" src={bracket} /></a></td>
        </tr>
        <tr>
            <td><span data-sid="30" title="11/7/10 - 3/13/11">24</span></td>
            <td><Link className="underline decoration-dotted" to={"/team/1178/"}>CNB Gaming</Link></td>
            <td className="centerrowicon"><a href="http://files.tpgleague.org/s24saplayoffs.jpg" target="_blank" rel="noreferrer"><img alt="bracket" src={bracket} /></a></td>
        </tr>
        <tr>
            <td><span data-sid="27" title="6/27/10 - 10/17/10">23</span></td>
            <td><Link className="underline decoration-dotted" to={"/team/965/"}>DreamWorks</Link></td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td><span data-sid="26" title="2/15/09 - 6/5/09">22</span></td>
            <td><Link className="underline decoration-dotted" to={"/team/965/"}>DreamWorks</Link></td>
            <td className="centerrowicon"><a href="http://files.tpgleague.org/dod6v6/s22_sa_playoffs.gif" target="_blank" rel="noreferrer"><img alt="bracket" src={bracket} /></a></td>
        </tr>
        </table>
        Notes:<em className="text-sm">
        <ul>
            <li>Season numbers correspond to the primary (North America) league as this was considered a separate conference of the same league.</li>
        </ul>
        </em>

        <br />
        <h3 className="ml-1 font-bold text-lg text-blue-600">TPG Day of Defeat 6v6 - Europe (8/17/12 - 7/2/13)</h3>
        <table className="pastchamps">
        <tr>
            <th>Season</th>
            <th>Open</th>
            <th>Main</th>
            <th>Invite</th>
        </tr>
        <tr>
            <td><span data-sid="" title="04/07/13 - 07/02/13">3</span></td>
            <td><Link className="underline decoration-dotted" to={"/team/1965/"}>Soldiers of Shadows</Link></td>
            <td><Link className="underline decoration-dotted" to={"/team/1977/"}>Gamerz</Link></td>
            <td><Link className="underline decoration-dotted" to={"/team/1936/"}>Best Friends Forever</Link></td>
        </tr>
        <tr>
            <td><span data-sid="" title="12/09/12 - 03/24/13">2</span></td>
            <td>&nbsp;</td>
            <td><Link className="underline decoration-dotted" to={"/team/1933/"}>old basterdZ</Link></td>
            <td><Link className="underline decoration-dotted" to={"/team/1936/"}>Best Friends Forever</Link></td>
        </tr>
        <tr>
            <td><span data-sid="" title="08/17/12 - 11/18/12">1</span></td>
            <td><Link className="underline decoration-dotted" to={"/team/1841/"}>RenameD*Gaming</Link></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
        </tr>
        </table>

        <br />
        <h3 className="ml-1 font-bold text-lg text-blue-600">TPG2 Day of Defeat 6v6 (11/23/04 - 10/18/05)</h3>
        <table className="pastchamps">
        <tr>
            <th>Season</th>
            <th>Lower</th>
            <th>Middle</th>
            <th>Upper</th>
        </tr>
        <tr>
            <td><span data-sid="" title="7/26/05 - 10/18/05">3</span></td>
            <td>handicap</td>
            <td><a className="underline decoration-dotted" href="https://web.archive.org/web/20050519161007/http://dod2.tpgleague.org/team.info.asp?id=147" target="_blank" rel="noreferrer">Gun Slingin' Southerners</a></td>
            <td>vEx</td>
        </tr>
        <tr>
            <td><span data-sid="" title="3/8/05 - 7/5/05">2</span></td>
            <td><a className="underline decoration-dotted" href="https://web.archive.org/web/20050520163347/http://dod2.tpgleague.org/team.info.asp?id=150" target="_blank" rel="noreferrer">sKillGamer'z</a></td>
            <td><a className="underline decoration-dotted" href="https://web.archive.org/web/20050517151737/http://dod2.tpgleague.org/team.info.asp?id=137" target="_blank" rel="noreferrer">Nexus</a></td>
            <td><a className="underline decoration-dotted" href="https://web.archive.org/web/20050109210006/http://dod2.tpgleague.org/team.info.asp?id=25" target="_blank" rel="noreferrer">RBC</a></td>
        </tr>
        <tr>
            <td><span data-sid="" title="11/23/04 - 2/15/05">1</span></td>
            <td><a className="underline decoration-dotted" href="https://web.archive.org/web/20050301002329/http://dod2.tpgleague.org/team.info.asp?id=64" target="_blank" rel="noreferrer">Lost Souls of [PARA247]</a></td>
            <td><a className="underline decoration-dotted" href="https://web.archive.org/web/20041114195218/http://dod2.tpgleague.org/team.info.asp?id=37" target="_blank" rel="noreferrer">Can't Operate Weapons</a></td>
            <td><a className="underline decoration-dotted" href="https://web.archive.org/web/20050224061916/http://dod2.tpgleague.org/team.info.asp?id=27" target="_blank" rel="noreferrer">Team risK</a></td>
        </tr>
        </table>
        Notes:<em className="text-sm">
        <ul>
            <li>Links to teams on archive.org or other external sites are as close to the timeframe of the corresponding season as possible.</li>
        </ul>
        </em>

        </div>
    );
}