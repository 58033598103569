import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Link } from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1976d2",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function UserDetails(props) {
  const [userRosterHistory, setUserRosterHistory] = useState(null);

  useEffect(() => {
    if (props.userId !== 0) {
      fetch(process.env.REACT_APP_API_URL + '/api/Player/PlayerRosterHistory?userId=' + props.userId)
      .then(response => response.json())
      .then(data => setUserRosterHistory(data));
    }
  }, [props.userId]);

  return (
    <>
    {(userRosterHistory === null) ? <h3 style={{margin: "15px 0px 0px 15px"}}>Loading roster history...</h3>
    : (userRosterHistory.length === 0) ? <h3 style={{margin: "15px 0px 0px 15px"}}>No roster history found for this user.</h3>
    :
    <>
    <div className="px-4 py-5 sm:px-6">
      <h3 className="text-lg font-bold leading-6 text-blue-600">
        Roster History
      </h3>
      <p className="mt-1 max-w-2xl text-sm text-gray-500">Pay attention to join & leave dates. Sometimes people joined a roster and left before playing with that team.</p>
      <p className="mt-1 max-w-2xl text-sm text-gray-500">Just because someone joined a roster doesn't mean they played any games with that team.</p>
      <p className="mt-1 max-w-2xl text-sm text-gray-500">Additionally, teams could have changed names during the listed timespan.</p>
      <p className="mt-1 max-w-2xl text-sm text-gray-500">The team name listed is the last name used by that particular team.</p>
    </div>
    <div style={{margin: "0px 0px 25px 25px"}}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} size="small" aria-label="user roster history">
          <TableHead>
            <TableRow>
              <StyledTableCell>League</StyledTableCell>
              <StyledTableCell>Team Tag</StyledTableCell>
              <StyledTableCell>Team Name (last used)</StyledTableCell>
              <StyledTableCell>Handle</StyledTableCell>
              <StyledTableCell>Join Date</StyledTableCell>
              <StyledTableCell>Leave Date</StyledTableCell>
              <StyledTableCell>Game ID</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userRosterHistory.map((row) => (
              <StyledTableRow key={row.rid}>
                <StyledTableCell>{row.league_title}</StyledTableCell>
                <StyledTableCell><Link className="text-blue-500 underline" to={"/team/" + row.tid}>{row.team_tag}</Link></StyledTableCell>
                <StyledTableCell><Link className="text-blue-500 underline" to={"/team/" + row.tid}>{row.team_name}</Link></StyledTableCell>
                <StyledTableCell>{row.handle}</StyledTableCell>
                <StyledTableCell>{moment(row.join_date).format("MMMM Do, YYYY")}</StyledTableCell>
                <StyledTableCell>{row.leave_date === '0001-01-01T00:00:00' ? '' : moment(row.leave_date).format("MMMM Do, YYYY")}</StyledTableCell>
                <StyledTableCell>{row.gid}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </div>
      </>
    }
    </>
  );
}
