import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Collapse from '@mui/material/Collapse';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PersonIcon from '@mui/icons-material/Person';
import CheckIcon from '@mui/icons-material/Check';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import moment from 'moment';
import { Link } from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#1976d2",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

export default function TeamRosterHistory(props) {
    const [teamRosterHistory, setTeamRosterHistory] = useState(null);
    const [isRosterHistoryOpen, setIsRosterHistoryOpen] = React.useState(false);

    useEffect(() => {
        if (props.teamId !== 0) {
          fetch(process.env.REACT_APP_API_URL + '/api/Team/TeamRosterHistory?teamId=' + props.teamId)
          .then(response => response.json())
          .then(data => setTeamRosterHistory(data));
        }
      }, [props.teamId]);

      return (
        <>
        {(teamRosterHistory === null) ? <h3 style={{margin: "15px 0px 0px 15px"}}>Loading roster history...</h3>
        : (teamRosterHistory.length === 0) ? <h3 style={{margin: "15px 0px 0px 15px"}}>No roster history found for this team.</h3>
        :
        <>
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg font-bold leading-6 text-blue-600">
            <IconButton onClick={() => setIsRosterHistoryOpen(!isRosterHistoryOpen)} aria-label="expand roster history" size="small">
              {isRosterHistoryOpen ? <KeyboardArrowDownIcon />  : <KeyboardArrowRightIcon />}
            </IconButton>
            <span className="cursor-pointer" onClick={() => setIsRosterHistoryOpen(!isRosterHistoryOpen)}>Team Roster History</span>
          </h3>
          <div style={{display: (isRosterHistoryOpen ? 'block' : 'none' )}}>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">Pay attention to join & leave dates. Sometimes people joined a roster and left before playing with that team.</p>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">Just because someone joined a roster doesn't mean they played any games with that team.</p>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">Players not listed as scheduler or reporter could have been one of these roles previously.</p>
          </div>
        </div>
        <Collapse in={isRosterHistoryOpen} timeout={0} unmountOnExit>
        <div style={{margin: "0px 0px 25px 25px"}}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} size="small" aria-label="team roster history">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Handle</StyledTableCell>
                  <StyledTableCell>Join Date</StyledTableCell>
                  <StyledTableCell>Leave Date</StyledTableCell>
                  <StyledTableCell>Removed By</StyledTableCell>
                  <StyledTableCell>Game ID</StyledTableCell>
                  <StyledTableCell>Scheduler</StyledTableCell>
                  <StyledTableCell>Reporter</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {teamRosterHistory.map((row) => (
                  <StyledTableRow key={row.rid}>
                    <StyledTableCell><Link className="text-blue-500 underline" to={"/user/" + row.uid}>{row.handle
                      ? <>{row.handle}</>
                      : <span className="text-black" title="Player had no handle on the roster or user account"><PersonIcon /></span>
                    }</Link>
                    </StyledTableCell>
                    <StyledTableCell>{moment(row.join_date).format("MMMM Do, YYYY")}</StyledTableCell>
                    <StyledTableCell>{row.leave_date === '0001-01-01T00:00:00' ? '' : moment(row.leave_date).format("MMMM Do, YYYY")}</StyledTableCell>
                    <StyledTableCell>{row.leave_date !== '0001-01-01T00:00:00' ? row.removed_by : ''}</StyledTableCell>
                    <StyledTableCell>{row.gid}</StyledTableCell>
                    <StyledTableCell>{row.permission_reschedule ? <CheckIcon /> : ''}</StyledTableCell>
                    <StyledTableCell>{row.permission_report ? <CheckIcon /> : ''}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        </Collapse>
          </>
        }
        </>
      );
}