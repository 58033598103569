import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Link } from "react-router-dom";
import getFlag from '../../CountryHelper.js';
import { tryHyperlinkUserProvidedUrl } from '../../UrlHelper.js';

export default function TeamDetails(props) {
  const [teamDetails, setTeamDetails] = useState(null);
  let createDate = null,
      lastModifiedDate = null,
      avatarUrl = '',
      orgOwnerDisplayName = '',
      captainDisplayName = '',
      countryFlag = '🌎';

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + '/api/Team/Get?teamId=' + props.teamId)
    .then(response => response.json())
    .then(data => setTeamDetails(data));
  }, [props.teamId]);

  if (teamDetails !== null) {
    createDate = moment(teamDetails.create_date).format("dddd, MMMM Do, YYYY");
    lastModifiedDate = moment(teamDetails.last_modified_date).format("dddd, MMMM Do, YYYY");
    avatarUrl = tryHyperlinkUserProvidedUrl(teamDetails.user_avatar_url);
    countryFlag = teamDetails.org_country_code ? getFlag(teamDetails.org_country_code) : '🌎';
    orgOwnerDisplayName = teamDetails.org_owner_handle ? teamDetails.org_owner_handle : teamDetails.org_owner_name;
    captainDisplayName = teamDetails.captain_handle ? teamDetails.captain_handle : teamDetails.captain_name;
  }

  return (
    <>
    {(teamDetails === null) ? <h3 style={{marginLeft: "15px"}}>Loading team data...</h3>
    : (teamDetails.tid === 0) ? <h3 style={{marginLeft: "15px"}}>The team ID provided is either invalid or the team has been removed.</h3>
    :
    <div className="overflow-hidden bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg font-bold leading-6 text-blue-600">
          {teamDetails.team_name}
        </h3>
        <p className="mt-1 max-w-2xl text-base text-gray-500">Team details and roster history.</p>
        <p className="italic text-xs" style={{marginTop: "8px"}}>All data is current as of the last time the TPG site was up. Captain, server location, etc... all could have been different at some point. Only name and tag changes were recorded.</p>
      </div>
      <div className="border-t border-gray-200">
        <dl>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Tag</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{teamDetails.tag}</dd>
          </div>
          {(teamDetails.previous_names || teamDetails.previous_tags) &&
          <>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Previous Names</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{teamDetails.previous_names}</dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Previous Tags</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{teamDetails.previous_tags}</dd>
            </div>
          </>
          }
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Captain</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              {teamDetails.captain_uid
              ? <Link className="text-blue-500 underline" to={"/User/" + teamDetails.captain_uid}>{captainDisplayName}</Link>
              : <span className="italic">Captaincy Vacant</span>
              }
            </dd>
          </div>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">League</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{teamDetails.league_title}</dd>
          </div>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Matches Played or Won/Loss by FF</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{teamDetails.matches_played_or_forfeit} &nbsp;<span className="italic text-xs">(All reported matches. Win/loss and regular season/playoffs breakdown coming soon!)</span></dd>
          </div>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Parent Organization</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{teamDetails.org_name} {countryFlag}</dd>
          </div>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Parent Organization Website</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{teamDetails.org_website}</dd>
          </div>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Parent Organization IRC Channel</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{teamDetails.org_irc}</dd>
          </div>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Parent Organization Owner Handle</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0"><Link className="text-blue-500 underline" to={"/User/" + teamDetails.org_owner_uid}>{orgOwnerDisplayName}</Link></dd>
          </div>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Team Avatar URL</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{avatarUrl}</dd>
          </div>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Last Known Game Server Location</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{teamDetails.server_location}</dd>
          </div>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Date created on new TPG Website</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{createDate}</dd>
          </div>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Date profile last modified</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{lastModifiedDate}</dd>
          </div>
        </dl>
      </div>
    </div>
    }
    </>
  );
}
