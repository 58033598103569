function PageNotFound() {
    return (
        <div style={{marginLeft: "25px"}}>
            <h1 style={{fontSize: "3rem", color: "#767676" }}>404 Page Not Found</h1>
            <h2 style={{fontSize: "2rem", color: "#767676", marginBottom: "25px" }}>Please use the menu to navigate to a page or follow one of the links below</h2>

            <h4>If you are looking for files such as maps and configs visit <a className="text-blue-500 underline" href="https://github.com/tpgleague/tpgleague.github.io/tree/master/files.tpgleague.org">files.tpgleague.org</a></h4>
            <a className="text-blue-500 underline" href="https://github.com/tpgleague/tpgleague.github.io">Visit Here to view the full source code for the TPG League website</a><br/>
            <a className="text-blue-500 underline" href="https://discord.gg/4R4hhd5jkP">Day of Defeat 1.3 Community Discord</a> (12 mans and tournaments take place here)<br/>
            <a className="text-blue-500 underline" href="https://discord.gg/tmQp6XACSa">KTP League Discord</a> (league currently running as of 2023)<br/>
            <a className="text-blue-500 underline" href="https://discord.gg/PzRxFgS">TPG League Discord</a><br/>
            <a className="text-blue-500 underline" href="https://www.facebook.com/tpgleague">TPG League Facebook</a><br/>
            <a className="text-blue-500 underline" href="https://nineteeneleven.org/">Nineteen Eleven Forums and Community</a><br/>
            <a className="text-blue-500 underline" href="https://sp3c-dod.github.io/resources.html">Other files and resources from the former sp3c.org site</a><br/>
            <br/>
            <a className="text-blue-500 underline" href="https://www.17buddies.rocks/17b2/View/Maps/Gam/1/Mod/2/Cat/0/All/0/Pag/1/Day%20Of%20Defeat.html">17's Buddies DOD 1.3 Map Downloads</a><br/>
            <a className="text-blue-500 underline" href="https://www.advancedfx.org/download/">Half-Life Advanced Effects</a> (Can fix old or broken demos)<br/>
            <a className="text-blue-500 underline" href="https://www.youtube.com/@bulletfrenzy">BuLLeT FoDDeR's YouTube</a> (How-to Videos and other content)<br/>
            <a className="text-blue-500 underline" href="https://www.twitch.tv/coreymarko">Corey Marko's Twitch</a> (Live match broadcasts)<br/>
            <a className="text-blue-500 underline" href="https://www.twitch.tv/bulletfrenzy">BuLLeT FoDDeR's Twitch</a> (Live match broadcasts)<br/>
            <a className="text-blue-500 underline" href="https://www.youtube.com/@coreymarko3231">Corey Marko's YouTube</a> (DODCAST and Casts of Matches)<br/>
            <a className="text-blue-500 underline" href="https://www.youtube.com/@averagerainfall">Cory Price's YouTube</a> (How-to Videos)<br/>
        </div>
    )
  };

  export default PageNotFound;