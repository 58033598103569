import React from 'react';
import { useParams } from "react-router-dom";
import TeamDetails from './TeamDetails.js';
import TeamRosterHistory from './TeamRosterHistory.js';
import TeamMatchHistory from './TeamMatchHistory.js';

export default function Team() {
  let params = useParams();

  return (
    <div style={{marginBottom: "15px"}}>
      <TeamDetails teamId={params.teamId} key={'teamdetails' + params.teamId} />
      <TeamRosterHistory teamId={params.teamId} key={'teamrosterhistory' + params.teamId} />
      <TeamMatchHistory teamId={params.teamId} key={'teammatchhistory' + params.teamId} />
    </div>
  );
}
