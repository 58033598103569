import { Link } from "react-router-dom";

export default function CsGoPastChamps() {
    return (
        <div>

        <h3 className="ml-1 font-bold text-lg text-blue-600">TPG Counter-Strike: Global Offensive (1/24/13 - 6/20/13)</h3>

        <table className="pastchamps">
        <tr>
            <th>Season</th>
            <th>Open</th>
        </tr>
        <tr>
            <td><span title="1/24/13 - 4/18/13">1 <span className="text-xs">(Winter 2013)</span></span></td>
            <td><Link className="underline decoration-dotted" to={"/team/1962"}>Powered by Jesus</Link></td>
        </tr>
        </table>
        Notes:<em className="text-sm">
        <ul>
            <li>Season 2: The season was started and ran from 6/6/13 - 6/20/13, but dissolved after 3 weeks of play and no winner was declared.</li>
        </ul>
        </em>

        </div>
    );
}