import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useParams, Link } from "react-router-dom";
import allies from '../../images/allies.gif';
import axis from '../../images/axis.gif';
import asterisk_orange from '../../images/asterisk_orange.png';
import MapLink from '../Map/MapLink.js';

function GetReportedBy(match) {
  const reportingTeam = match.report_by_tid === match.home_tid
    ? match.home_team_name
    : match.away_team_name;
  const reportingTeamLink = <Link className="text-blue-500 underline" to={"/team/" + match.report_by_tid}>{reportingTeam}</Link>;
  let reportedBy;

  if (match.reporting_admin_name) {
    reportedBy = <span>{match.reporting_admin_name} (<strong className="text-sm">Admin</strong>)</span>
  }
  else if (match.reporter_handle) {
    reportedBy = <><Link className="text-blue-500 underline" to={"/user/" + match.report_by_uid}>{match.reporter_handle}</Link> of team {reportingTeamLink}</>
  }
  else if (match.reporter_name) {
    reportedBy = <><Link className="text-blue-500 underline" to={"/user/" + match.report_by_uid}>{match.reporter_name}</Link> of team {reportingTeamLink}</>
  }
  else if (match.report_by_uid)
  {
    reportedBy = <em><Link className="text-blue-500 underline" to={"/user/" + match.report_by_uid}>Player</Link> on team {reportingTeamLink}</em>
  }
  else if (reportingTeam) {
    reportedBy = {reportingTeamLink}
  }
  else {
    reportedBy = <em>There is no information about who reported this match.</em>
  }

  return reportedBy;
}

function GetLosingTeam(match) {
  const { forfeit_home, forfeit_away, win_tid, away_tid, home_tid, away_team_name, home_team_name } = match;
  let losingTeam;

  if (forfeit_home && !forfeit_away) {
    losingTeam = {losingTeamId: home_tid, losingTeamName: home_team_name};
  }
  else if (forfeit_away && !forfeit_home) {
    losingTeam = {losingTeamId: away_tid, losingTeamName: away_team_name};
  }
  else {
    losingTeam = (win_tid === home_tid)
      ? {losingTeamId: away_tid, losingTeamName: away_team_name}
      : {losingTeamId: home_tid, losingTeamName: home_team_name};
  }

  return losingTeam;
}

function GetSideImage(sideName, round) {
  if (!sideName) return "Side " + round;

  let side;

  switch (sideName) {
    case "Allies":
      side = <img alt="Allies" src={allies} with="15px" height="15px" />
      break;
    case "Axis":
      side = <img alt="Axis" src={axis} with="15px" height="15px" />
      break;
    default:
      side = sideName;
  }

  return side;
}

function GetScoresTable(match, awayTeam, homeTeam) {
  return <table id="match_table" className="spaced_table">
    <tbody>
    <tr><th colSpan="3" style={{textAlign: 'center'}}>First Half</th></tr>
    <tr>
      <th>Side</th>
      <th>Team</th>
      <th align="right">Score</th>
    </tr>
    <tr>
      <td className="sidecol">{GetSideImage(match.away_side_first_half, 1)}</td>
      <td>{awayTeam}</td>
      <td align="center" className="scorecol">{match.away_score_first_half}</td>
    </tr>
    <tr>
      <td className="sidecol">{GetSideImage(match.home_side_first_half, 1)}</td>
      <td>{homeTeam}</td>
      <td align="center" className="scorecol">{match.home_score_first_half}</td>
    </tr>
    </tbody>

    <tbody>
    <tr><th colSpan="3" style={{textAlign: 'center'}}>Second Half</th></tr>
    <tr>
      <th>Side</th>
      <th>Team</th>
      <th>Score</th>
    </tr>
    <tr>
      <td className="sidecol">{GetSideImage(match.away_side_second_half, 2)}</td>
      <td>{awayTeam}</td>
      <td align="center" className="scorecol">{match.away_score_second_half}</td>
    </tr>
    <tr>
      <td className="sidecol">{GetSideImage(match.home_side_second_half, 2)}</td>
      <td>{homeTeam}</td>
      <td align="center" className="scorecol">{match.home_score_second_half}</td>
    </tr>
    </tbody>

    <tbody>
    <tr><th colSpan="3" style={{textAlign: 'center'}}>Final</th></tr>
    <tr>
      <th>Winner</th>
      <th>Team</th>
      <th>Score</th>
    </tr>
    <tr>
      <td className="sidecol">{match.away_score > match.home_score && <img alt="winner" src={asterisk_orange} with="15px" height="15px" />}</td>
      <td>{awayTeam}</td>
      <td align="center" className="scorecol">{match.away_score}</td>
    </tr>
    <tr>
    <td className="sidecol">{match.home_score > match.away_score && <img alt="winner" src={asterisk_orange} with="15px" height="15px" />}</td>
      <td>{homeTeam}</td>
      <td align="center" className="scorecol">{match.home_score}</td>
    </tr>
    </tbody>
  </table>;
}

export default function Match(props) {
  const [matchDetails, setMatchDetails] = useState(null);
  let params = useParams();
  let reportDate = null, defaultMatchDate = null, startDate = null, losingTeamId;
  let reportedBy = '', winningTeamName = '', losingTeamName = '';
  let forfeitText = null, scores = null,
      awayTeam, homeTeam, fullSeasonTitle, winningTeam = null, losingTeam = null;

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + '/api/Match/Get?matchId=' + params.matchId)
    .then(response => response.json())
    .then(data => setMatchDetails(data));
  }, [params.matchId]);

  if (matchDetails !== null) {
    reportDate = moment(matchDetails.report_date).format("dddd, MMMM Do, YYYY");
    defaultMatchDate = moment(matchDetails.default_match_date).format("dddd, MMMM Do, YYYY");
    startDate = moment(matchDetails.start_date).format("dddd, MMMM Do, YYYY");
    reportedBy = GetReportedBy(matchDetails);
    fullSeasonTitle = <>Season {matchDetails.season_number} ({matchDetails.season_title})</>;

    awayTeam = matchDetails.away_tid
      ? <Link to={"/team/" + matchDetails.away_tid}>{matchDetails.away_team_name}</Link>
      : (!matchDetails.forfeit_home && !matchDetails.forfeit_away ? <em className="text-base">Bye</em> : <em className="text-base">Opponent removed/rescheduled</em>);
    homeTeam = matchDetails.home_tid
      ? <Link to={"/team/" + matchDetails.home_tid}>{matchDetails.home_team_name}</Link>
      : (!matchDetails.forfeit_home && !matchDetails.forfeit_away ? <em className="text-base">Bye</em> : <em className="text-base">Opponent removed/rescheduled</em>);

    winningTeamName = matchDetails.win_tid === matchDetails.home_tid ? matchDetails.home_team_name : matchDetails.away_team_name;
    winningTeam = matchDetails.win_tid ? <Link to={"/team/" + matchDetails.win_tid}>{winningTeamName}</Link> : '';
    ({losingTeamId, losingTeamName} = GetLosingTeam(matchDetails));
    losingTeam = losingTeamId ? <Link to={"/team/" + losingTeamId}>{losingTeamName}</Link> : {losingTeamName};

    // Forfeit information
    if (matchDetails.forfeit_home && matchDetails.forfeit_away) {
      forfeitText = <><span className="font-bold">Double forfeit</span><br /><em className="text-sm">This could have happened because neither team could agree on a time to play or both teams violated a rule that results in a match being overturned.</em></>;
    }
    else if (matchDetails.forfeit_home || matchDetails.forfeit_away) {
      if (losingTeamId === 0) {
        forfeitText = <>
          <span className="font-bold">Match won by forfeit by</span> {winningTeam}<br/ >
          <span className="text-sm italic">The losing team name is missing likely because the losing team was moved to another division, removed from the league, or some other reason the admin wanted the winning team to have credit for a win</span>
        </>;
      }
      else if (matchDetails.win_tid === 0) {
        forfeitText = <>
          <span className="font-bold">Match FORFEITED by</span> {losingTeam}<br/ >
          <span className="text-sm italic">The winning team is not listed because they were rescheduled to play a makeup match. Forfeit wins were able to be rescheduled with another team that had a bye week or also won by forfeit.</span>
        </>;
      }
      else {
        forfeitText = <>
          <span className="font-bold">Match won by forfeit by</span> {winningTeam}<br/ >
          <span className="text-sm italic">{losingTeam} forfeited the match</span>
        </>;
      }
    }

    // Scores and bye weeks
    if (!forfeitText) {
      if (matchDetails.home_tid === 0) {
        scores = <>{awayTeam} had a <strong>BYE</strong> (no opponent scheduled)</>
      }
      else if (matchDetails.away_tid === 0) {
        scores = <>{homeTeam} had a <strong>BYE</strong> (no opponent scheduled)</>
      }
      else if (matchDetails.home_score === matchDetails.away_score) {
        scores = <><p className="pb-4">Game resulted in a {matchDetails.home_score}-{matchDetails.away_score} <strong>TIE</strong></p> {GetScoresTable(matchDetails, awayTeam, homeTeam)}</>;
      }
      else {
        scores = GetScoresTable(matchDetails, awayTeam, homeTeam);
      }
    }
    else{
      scores = forfeitText;
    }
  }

  return (
    <>
    {(matchDetails === null) ? <h3 style={{marginLeft: "15px"}}>Loading match data...</h3>
    : (matchDetails.mid === 0) ? <h3 style={{marginLeft: "15px"}}>The match ID provided is either invalid or the user has been removed.</h3>
    :
    <div className="pb-10">
    <div className="overflow-hidden bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg font-bold leading-6 text-blue-600">
        {matchDetails.stg_short_desc}: {awayTeam} vs {homeTeam}
        </h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">{matchDetails.league_title} - {fullSeasonTitle}</p>
        <div className="pt-7 pb-5">
        {scores}
        </div>
      </div>
      <div className="border-t border-gray-200">
        <dl>
        <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Map</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0"><MapLink mapName={matchDetails.map_title} mapUrl={matchDetails.map_url} /></dd>
          </div>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Default Match Date</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{defaultMatchDate}</dd>
          </div>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Match Scheduled Start Date</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{startDate}</dd>
          </div>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">
              {matchDetails.report_by_aid ? <>Match Reported or Modified On</> : <>Match Reported On</>}
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{reportDate}</dd>
          </div>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">
              {matchDetails.reporting_admin_name ? <>Reported or Modified By</> : <>Reported By</>}
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{reportedBy}</dd>
          </div>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Match Comments</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              {matchDetails.match_comments}
            </dd>
          </div>
        </dl>
      </div>
    </div>
    </div>
    }
    </>
  );
}
