import * as React from 'react';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import GroupsIcon from '@mui/icons-material/Groups';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useNavigate } from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1976d2",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function TeamSearchResults(props) {
  const navigate = useNavigate();

  function ViewTeamDetails(tid) {
    navigate("/team/" + tid);
  }

  return (
    <>
      {
      props.searchResults.length === 0
        ? <h3 style={{margin: "-30px 0px 0px 15px"}}>No Results Found.</h3>
        :
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} size="small" aria-label="team search results">
          <TableHead>
            <TableRow>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell>Team Name</StyledTableCell>
              <StyledTableCell>Team Tag</StyledTableCell>
              <StyledTableCell>League</StyledTableCell>
              <StyledTableCell>Captain Name</StyledTableCell>
              <StyledTableCell>Previous Names</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.searchResults.map((row) => (
              <StyledTableRow key={row.tid}>
                <StyledTableCell component="th" scope="row">
                <IconButton aria-label="View Team Details" onClick={() => ViewTeamDetails(row.tid)}>
                  <GroupsIcon />
                </IconButton>
                </StyledTableCell>
                <StyledTableCell>{row.team_name}</StyledTableCell>
                <StyledTableCell>{row.tag}</StyledTableCell>
                <StyledTableCell>{row.league_title}</StyledTableCell>
                <StyledTableCell>
                  {row.captain_uid
                    ? (row.captain_handle ? row.captain_handle : row.captain_name )
                    : <span className="italic">Captaincy Vacant</span>}
                </StyledTableCell>
                <StyledTableCell>{row.previous_names}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      }
    </>
  );
}