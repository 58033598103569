import React from 'react';
import { useParams } from "react-router-dom";
import UserDetails from './UserDetails.js';
import UserRosterHistory from './UserRosterHistory.js';

export default function User() {
  let params = useParams();

  return (
    <div style={{marginBottom: "15px"}}>
      <UserDetails userId={params.userId} key={'userdetails' + params.userId} />
      <UserRosterHistory userId={params.userId} key={'userrosterhistory' + params.userId} />
    </div>
  );
}
