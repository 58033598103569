import { tryGetAbsoluteTpgFilesUrl } from '../../UrlHelper.js';

export default function MapLink(props) {
  const {mapName, mapUrl} = props;

  const absolute_map_url = tryGetAbsoluteTpgFilesUrl(mapUrl);

  return absolute_map_url
      ? <a className="text-blue-500 underline" href={absolute_map_url}>{mapName}</a>
      : mapName;
}