import React, { useState, useEffect } from 'react';
import moment from 'moment';
import getFlag from '../../CountryHelper.js';
import { tryHyperlinkUserProvidedUrl } from '../../UrlHelper.js';
import { tryHyperlinkUserProvidedSteamUrl } from '../../UrlHelper.js';

export default function UserDetails(props) {
  const [userDetails, setUserDetails] = useState(null);
  let joinDate = null, steamUrl = '', avatarUrl = '', countryDetails = '';

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + '/api/Player/Get?userId=' + props.userId)
    .then(response => response.json())
    .then(data => setUserDetails(data));
  }, [props.userId]);

  if (userDetails !== null) {
    joinDate = moment(userDetails.join_date).format("dddd, MMMM Do, YYYY");
    steamUrl =  tryHyperlinkUserProvidedSteamUrl(userDetails.steam_profile_url);
    avatarUrl = tryHyperlinkUserProvidedUrl(userDetails.user_avatar_url);
    const flag = userDetails.ccode ? getFlag(userDetails.ccode) : '🌎';
    if (userDetails.ccode && userDetails.country) {
      countryDetails = <span title={userDetails.country}> {flag}</span>;
    } else if (userDetails.ccode) {
      countryDetails = ' ' + flag;
    }
  }

  return (
    <>
    {(userDetails === null) ? <h3 style={{marginLeft: "15px"}}>Loading player data...</h3>
    : (userDetails.uid === 0) ? <h3 style={{marginLeft: "15px"}}>The user ID provided is either invalid or the user has been removed.</h3>
    :
    <div className="overflow-hidden bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg font-bold leading-6 text-blue-600">
          {userDetails.handle ? userDetails.handle : userDetails.fullname}
        </h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">Player details and roster history.</p>
      </div>
      <div className="border-t border-gray-200">
        <dl>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">{userDetails.hide_lastname === true ? "First Name" : "Full name"}</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{userDetails.fullname}</dd>
          </div>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Location</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{userDetails.city}{userDetails.city ? ", " : ""}{userDetails.state}{userDetails.state ? " " : userDetails.country}{countryDetails}</dd>
          </div>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">New TPG Website Join Date</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{joinDate}</dd>
          </div>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Steam Profile URL</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{steamUrl}</dd>
          </div>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">User Avatar URL</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{avatarUrl}</dd>
          </div>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">User Comments</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              {userDetails.user_comments}
            </dd>
          </div>
        </dl>
      </div>
    </div>
    }
    </>
  );
}
