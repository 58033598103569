export default function getFlag(countryCode) {
    var ccode = countryCode.toUpperCase();
    if(ccode ==='AD') return '🇦🇩';
    if(ccode ==='AE') return '🇦🇪';
    if(ccode ==='AF') return '🇦🇫';
    if(ccode ==='AG') return '🇦🇬';
    if(ccode ==='AI') return '🇦🇮';
    if(ccode ==='AL') return '🇦🇱';
    if(ccode ==='AM') return '🇦🇲';
    if(ccode ==='AO') return '🇦🇴';
    if(ccode ==='AQ') return '🇦🇶';
    if(ccode ==='AR') return '🇦🇷';
    if(ccode ==='AS') return '🇦🇸';
    if(ccode ==='AT') return '🇦🇹';
    if(ccode ==='AU') return '🇦🇺';
    if(ccode ==='AW') return '🇦🇼';
    if(ccode ==='AX') return '🇦🇽';
    if(ccode ==='AZ') return '🇦🇿';
    if(ccode ==='BA') return '🇧🇦';
    if(ccode ==='BB') return '🇧🇧';
    if(ccode ==='BD') return '🇧🇩';
    if(ccode ==='BE') return '🇧🇪';
    if(ccode ==='BF') return '🇧🇫';
    if(ccode ==='BG') return '🇧🇬';
    if(ccode ==='BH') return '🇧🇭';
    if(ccode ==='BI') return '🇧🇮';
    if(ccode ==='BJ') return '🇧🇯';
    if(ccode ==='BL') return '🇧🇱';
    if(ccode ==='BM') return '🇧🇲';
    if(ccode ==='BN') return '🇧🇳';
    if(ccode ==='BO') return '🇧🇴';
    if(ccode ==='BQ') return '🇧🇶';
    if(ccode ==='BR') return '🇧🇷';
    if(ccode ==='BS') return '🇧🇸';
    if(ccode ==='BT') return '🇧🇹';
    if(ccode ==='BV') return '🇧🇻';
    if(ccode ==='BW') return '🇧🇼';
    if(ccode ==='BY') return '🇧🇾';
    if(ccode ==='BZ') return '🇧🇿';
    if(ccode ==='CA') return '🇨🇦';
    if(ccode ==='CC') return '🇨🇨';
    if(ccode ==='CD') return '🇨🇩';
    if(ccode ==='CF') return '🇨🇫';
    if(ccode ==='CG') return '🇨🇬';
    if(ccode ==='CH') return '🇨🇭';
    if(ccode ==='CI') return '🇨🇮';
    if(ccode ==='CK') return '🇨🇰';
    if(ccode ==='CL') return '🇨🇱';
    if(ccode ==='CM') return '🇨🇲';
    if(ccode ==='CN') return '🇨🇳';
    if(ccode ==='CO') return '🇨🇴';
    if(ccode ==='CR') return '🇨🇷';
    if(ccode ==='CU') return '🇨🇺';
    if(ccode ==='CV') return '🇨🇻';
    if(ccode ==='CW') return '🇨🇼';
    if(ccode ==='CX') return '🇨🇽';
    if(ccode ==='CY') return '🇨🇾';
    if(ccode ==='CZ') return '🇨🇿';
    if(ccode ==='DE') return '🇩🇪';
    if(ccode ==='DJ') return '🇩🇯';
    if(ccode ==='DK') return '🇩🇰';
    if(ccode ==='DM') return '🇩🇲';
    if(ccode ==='DO') return '🇩🇴';
    if(ccode ==='DZ') return '🇩🇿';
    if(ccode ==='EC') return '🇪🇨';
    if(ccode ==='EE') return '🇪🇪';
    if(ccode ==='EG') return '🇪🇬';
    if(ccode ==='EH') return '🇪🇭';
    if(ccode ==='ER') return '🇪🇷';
    if(ccode ==='ES') return '🇪🇸';
    if(ccode ==='ET') return '🇪🇹';
    if(ccode ==='FI') return '🇫🇮';
    if(ccode ==='FJ') return '🇫🇯';
    if(ccode ==='FK') return '🇫🇰';
    if(ccode ==='FM') return '🇫🇲';
    if(ccode ==='FO') return '🇫🇴';
    if(ccode ==='FR') return '🇫🇷';
    if(ccode ==='GA') return '🇬🇦';
    if(ccode ==='GB') return '🇬🇧';
    if(ccode ==='GD') return '🇬🇩';
    if(ccode ==='GE') return '🇬🇪';
    if(ccode ==='GF') return '🇬🇫';
    if(ccode ==='GG') return '🇬🇬';
    if(ccode ==='GH') return '🇬🇭';
    if(ccode ==='GI') return '🇬🇮';
    if(ccode ==='GL') return '🇬🇱';
    if(ccode ==='GM') return '🇬🇲';
    if(ccode ==='GN') return '🇬🇳';
    if(ccode ==='GP') return '🇬🇵';
    if(ccode ==='GQ') return '🇬🇶';
    if(ccode ==='GR') return '🇬🇷';
    if(ccode ==='GS') return '🇬🇸';
    if(ccode ==='GT') return '🇬🇹';
    if(ccode ==='GU') return '🇬🇺';
    if(ccode ==='GW') return '🇬🇼';
    if(ccode ==='GY') return '🇬🇾';
    if(ccode ==='HK') return '🇭🇰';
    if(ccode ==='HM') return '🇭🇲';
    if(ccode ==='HN') return '🇭🇳';
    if(ccode ==='HR') return '🇭🇷';
    if(ccode ==='HT') return '🇭🇹';
    if(ccode ==='HU') return '🇭🇺';
    if(ccode ==='ID') return '🇮🇩';
    if(ccode ==='IE') return '🇮🇪';
    if(ccode ==='IL') return '🇮🇱';
    if(ccode ==='IM') return '🇮🇲';
    if(ccode ==='IN') return '🇮🇳';
    if(ccode ==='IO') return '🇮🇴';
    if(ccode ==='IQ') return '🇮🇶';
    if(ccode ==='IR') return '🇮🇷';
    if(ccode ==='IS') return '🇮🇸';
    if(ccode ==='IT') return '🇮🇹';
    if(ccode ==='JE') return '🇯🇪';
    if(ccode ==='JM') return '🇯🇲';
    if(ccode ==='JO') return '🇯🇴';
    if(ccode ==='JP') return '🇯🇵';
    if(ccode ==='KE') return '🇰🇪';
    if(ccode ==='KG') return '🇰🇬';
    if(ccode ==='KH') return '🇰🇭';
    if(ccode ==='KI') return '🇰🇮';
    if(ccode ==='KM') return '🇰🇲';
    if(ccode ==='KN') return '🇰🇳';
    if(ccode ==='KP') return '🇰🇵';
    if(ccode ==='KR') return '🇰🇷';
    if(ccode ==='KW') return '🇰🇼';
    if(ccode ==='KY') return '🇰🇾';
    if(ccode ==='KZ') return '🇰🇿';
    if(ccode ==='LA') return '🇱🇦';
    if(ccode ==='LB') return '🇱🇧';
    if(ccode ==='LC') return '🇱🇨';
    if(ccode ==='LI') return '🇱🇮';
    if(ccode ==='LK') return '🇱🇰';
    if(ccode ==='LR') return '🇱🇷';
    if(ccode ==='LS') return '🇱🇸';
    if(ccode ==='LT') return '🇱🇹';
    if(ccode ==='LU') return '🇱🇺';
    if(ccode ==='LV') return '🇱🇻';
    if(ccode ==='LY') return '🇱🇾';
    if(ccode ==='MA') return '🇲🇦';
    if(ccode ==='MC') return '🇲🇨';
    if(ccode ==='MD') return '🇲🇩';
    if(ccode ==='ME') return '🇲🇪';
    if(ccode ==='MF') return '🇲🇫';
    if(ccode ==='MG') return '🇲🇬';
    if(ccode ==='MH') return '🇲🇭';
    if(ccode ==='MK') return '🇲🇰';
    if(ccode ==='ML') return '🇲🇱';
    if(ccode ==='MM') return '🇲🇲';
    if(ccode ==='MN') return '🇲🇳';
    if(ccode ==='MO') return '🇲🇴';
    if(ccode ==='MP') return '🇲🇵';
    if(ccode ==='MQ') return '🇲🇶';
    if(ccode ==='MR') return '🇲🇷';
    if(ccode ==='MS') return '🇲🇸';
    if(ccode ==='MT') return '🇲🇹';
    if(ccode ==='MU') return '🇲🇺';
    if(ccode ==='MV') return '🇲🇻';
    if(ccode ==='MW') return '🇲🇼';
    if(ccode ==='MX') return '🇲🇽';
    if(ccode ==='MY') return '🇲🇾';
    if(ccode ==='MZ') return '🇲🇿';
    if(ccode ==='NA') return '🇳🇦';
    if(ccode ==='NC') return '🇳🇨';
    if(ccode ==='NE') return '🇳🇪';
    if(ccode ==='NF') return '🇳🇫';
    if(ccode ==='NG') return '🇳🇬';
    if(ccode ==='NI') return '🇳🇮';
    if(ccode ==='NL') return '🇳🇱';
    if(ccode ==='NO') return '🇳🇴';
    if(ccode ==='NP') return '🇳🇵';
    if(ccode ==='NR') return '🇳🇷';
    if(ccode ==='NU') return '🇳🇺';
    if(ccode ==='NZ') return '🇳🇿';
    if(ccode ==='OM') return '🇴🇲';
    if(ccode ==='PA') return '🇵🇦';
    if(ccode ==='PE') return '🇵🇪';
    if(ccode ==='PF') return '🇵🇫';
    if(ccode ==='PG') return '🇵🇬';
    if(ccode ==='PH') return '🇵🇭';
    if(ccode ==='PK') return '🇵🇰';
    if(ccode ==='PL') return '🇵🇱';
    if(ccode ==='PM') return '🇵🇲';
    if(ccode ==='PN') return '🇵🇳';
    if(ccode ==='PR') return '🇵🇷';
    if(ccode ==='PS') return '🇵🇸';
    if(ccode ==='PT') return '🇵🇹';
    if(ccode ==='PW') return '🇵🇼';
    if(ccode ==='PY') return '🇵🇾';
    if(ccode ==='QA') return '🇶🇦';
    if(ccode ==='RE') return '🇷🇪';
    if(ccode ==='RO') return '🇷🇴';
    if(ccode ==='RS') return '🇷🇸';
    if(ccode ==='RU') return '🇷🇺';
    if(ccode ==='RW') return '🇷🇼';
    if(ccode ==='SA') return '🇸🇦';
    if(ccode ==='SB') return '🇸🇧';
    if(ccode ==='SC') return '🇸🇨';
    if(ccode ==='SD') return '🇸🇩';
    if(ccode ==='SE') return '🇸🇪';
    if(ccode ==='SG') return '🇸🇬';
    if(ccode ==='SH') return '🇸🇭';
    if(ccode ==='SI') return '🇸🇮';
    if(ccode ==='SJ') return '🇸🇯';
    if(ccode ==='SK') return '🇸🇰';
    if(ccode ==='SL') return '🇸🇱';
    if(ccode ==='SM') return '🇸🇲';
    if(ccode ==='SN') return '🇸🇳';
    if(ccode ==='SO') return '🇸🇴';
    if(ccode ==='SR') return '🇸🇷';
    if(ccode ==='SS') return '🇸🇸';
    if(ccode ==='ST') return '🇸🇹';
    if(ccode ==='SV') return '🇸🇻';
    if(ccode ==='SX') return '🇸🇽';
    if(ccode ==='SY') return '🇸🇾';
    if(ccode ==='SZ') return '🇸🇿';
    if(ccode ==='TC') return '🇹🇨';
    if(ccode ==='TD') return '🇹🇩';
    if(ccode ==='TF') return '🇹🇫';
    if(ccode ==='TG') return '🇹🇬';
    if(ccode ==='TH') return '🇹🇭';
    if(ccode ==='TJ') return '🇹🇯';
    if(ccode ==='TK') return '🇹🇰';
    if(ccode ==='TL') return '🇹🇱';
    if(ccode ==='TM') return '🇹🇲';
    if(ccode ==='TN') return '🇹🇳';
    if(ccode ==='TO') return '🇹🇴';
    if(ccode ==='TR') return '🇹🇷';
    if(ccode ==='TT') return '🇹🇹';
    if(ccode ==='TV') return '🇹🇻';
    if(ccode ==='TW') return '🇹🇼';
    if(ccode ==='TZ') return '🇹🇿';
    if(ccode ==='UA') return '🇺🇦';
    if(ccode ==='UG') return '🇺🇬';
    if(ccode ==='UM') return '🇺🇲';
    if(ccode ==='US') return '🇺🇸';
    if(ccode ==='UY') return '🇺🇾';
    if(ccode ==='UZ') return '🇺🇿';
    if(ccode ==='VA') return '🇻🇦';
    if(ccode ==='VC') return '🇻🇨';
    if(ccode ==='VE') return '🇻🇪';
    if(ccode ==='VG') return '🇻🇬';
    if(ccode ==='VI') return '🇻🇮';
    if(ccode ==='VN') return '🇻🇳';
    if(ccode ==='VU') return '🇻🇺';
    if(ccode ==='WF') return '🇼🇫';
    if(ccode ==='WS') return '🇼🇸';
    if(ccode ==='XK') return '🇽🇰';
    if(ccode ==='YE') return '🇾🇪';
    if(ccode ==='YT') return '🇾🇹';
    if(ccode ==='ZA') return '🇿🇦';
    if(ccode ==='ZM') return '🇿🇲';
    return '🌎';
  }