import { Link  } from "react-router-dom";
import bracket from '../../images/bracket.png';

export default function DodSource3v3PastChamps() {
    return (
        <>
        <h3 className="ml-1 font-bold text-lg text-blue-600">TPG Day of Defeat: Source 3v3 (2/1/07 - 5/10/07)</h3>
        <table className="pastchamps">
        <tr>
            <th title="Hover over the season number for the full date range of the season">Season</th>
            <th colspan="2">Open</th>
        </tr>
        <tr>
            <td><span title="2/1/07 - 5/10/07">1 <span className="text-xs">(Spring 2007)</span></span></td>
            <td><Link className="underline decoration-dotted" to={"/team/355"}>Clan [Pokemon]</Link></td>
            <td className="centerrowicon"><a href="http://files.tpgleague.org/dods3v3/s1_lower.gif" target="_blank" rel="noreferrer"><img alt="bracket" src={bracket} /></a></td>
        </tr>
        </table>
        </>
    );
}