import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/layout/layout";
import User from './components/User/User';
import Team from './components/Team/Team';
import Match from './components/Match/Match';
import UserSearch from './components/UserSearch/UserSearch.js';
import TeamSearch from './components/TeamSearch/TeamSearch.js';
import Suspensions from './components/Suspensions/Suspensions.js';
import PastChampions from './components/PastChampions/PastChampions.js';
import PageNotFound from './components/PageNotFound/PageNotFound.js';
import Standings from "./components/Standings/Standings";
import './App.css';

function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Layout />}>
            <Route index element={<UserSearch />} />
            <Route exact path="user/:userId" element={<User />} />
            <Route exact path="team/:teamId" element={<Team />} />
            <Route exact path="match/:matchId" element={<Match />} />
            <Route exact path="usersearch" element={<UserSearch />} />
            <Route exact path="teamsearch" element={<TeamSearch />} />
            <Route exact path="suspensions" element={<Suspensions />} />
            <Route exact path="pastchamps" element={<PastChampions />} />
            <Route exact path="standings/:sid" element={<Standings />} />
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
  );
}

export default App;
