import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useNavigate  } from "react-router-dom";

const navItems = ['User Search', 'Team Search', 'Suspensions', 'Past Champions'];

function Navigation() {
  const navigate = useNavigate();

  function handleMenuItemClick(menuItem){
    switch (menuItem){
        case "User Search":
          navigate("/usersearch");
          break;
        case "Team Search":
          navigate("/teamsearch");
          break;
        case "Suspensions":
          navigate("/suspensions");
          break;
        case "Past Champions":
          navigate("/pastchamps?league=dod6");
          break;
        default: // Do Nothing
    }
  }

  return (
    <AppBar component="nav">
    <Toolbar>
      <Typography
        variant="h6"
        component="div"
        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
      >
        TPG Data Lookup
      </Typography>
      <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
        {navItems.map((item) => (
          <Button key={item} sx={{ color: '#fff' }}
            onClick={() => handleMenuItemClick(item)}>
            {item}
          </Button>
        ))}
      </Box>
    </Toolbar>
  </AppBar>
  );
}
export default Navigation;