import { Link  } from "react-router-dom";

export default function HawkenPastChamps() {
    return (
        <div>
            Hawken Past Champs Coming Soon!
  {/*
<h3>Hawken</h3>>

<table border="0">
<tr>
    <th>Season &nbsp;</th>
    <th>Main</th>
</tr>
<tr>
    <td valign="top"><span title="05/18/14 - 08/03/14">1</span></td>
    <td valign="top"><a class="tpglinkalt" href="http://www.tpgleague.org/hwk/team/2158/">Unobtainium Unicorns</a></td>
    </tr>
</table> */}
        </div>
    );
}