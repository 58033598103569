import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Link } from "react-router-dom";
import { styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1976d2",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  const matchDetails = row.mid !== 0
    ? `Sesaon ${row.season_number} (${row.season_title}) ${row.stg_short_desc} on ${moment(row.match_start_date).format("MMMM Do, YYYY")}: ${row.away_name} vs ${row.home_name}`
    : "";

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowDownIcon />  : <KeyboardArrowRightIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{row.handle.replace('[redacted]', '∎∎∎∎∎∎')}</TableCell>
        <TableCell>{row.reason}</TableCell>
        <TableCell>{moment(row.start_date).format("MMMM Do, YYYY")}</TableCell>
        <TableCell>{moment(row.end_date).format("MMMM Do, YYYY")}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
            <div className="overflow-hidden bg-white shadow sm:rounded-lg">
              <div className="border-t border-gray-200">
                <dl>
                  <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">League</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-4 sm:mt-0">
                      {row.league_title
                      ? <span >{row.league_title}</span>
                      : <span className="italic">No league associated with this suspension</span>}
                    </dd>
                  </div>
                  <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Team Name</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-4 sm:mt-0">
                      {row.team_name
                      ? <span>
                          {row.tid
                             ? <Link className="text-blue-500 underline" to={"/team/" + row.tid}>{row.team_name}</Link>
                             : <>{row.team_name}</>
                          }
                        </span>
                      : <span className="italic">No team associated with this suspension</span>}
                    </dd>
                  </div>
                  <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Match</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-4 sm:mt-0">
                      {row.mid !== 0
                      ? <span>{matchDetails}</span>
                      : <span className="italic">No match associated with this suspension.</span>}
                    </dd>
                  </div>
                  <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Game ID{row.gids === null || row.gids.includes(',') ? "s" : ""}</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-4 sm:mt-0">
                      {row.gids
                      ? <>{row.gids.split(",").map(gid => (
                         <Link key={gid} className="text-blue-500 underline" to={"/usersearch?searchTerm=" + encodeURI(gid)}>{gid}</Link>
                          )).reduce((allGids, gidlink) => allGids === null ? [gidlink] : [allGids, ', ', gidlink], null)}</>
                      : <span className="italic">No game IDs associated with this suspension</span>}
                    </dd>
                  </div>
                  <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">User Account{row.uids === null || row.uids.includes(',') ? "s" : ""}</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-4 sm:mt-0">
                      {row.uids
                      ? <>{row.uids.split(",").map(uid => (
                        <Link key={uid} className="text-blue-500 underline" to={"/user/" + uid}>{uid}</Link>
                        )).reduce((allUids, uidlink) => allUids === null ? [uidlink] : [allUids, ', ', uidlink], null)}</>
                      : <span className="italic">No user accounts associated with this suspension.</span>}
                    </dd>
                  </div>
                  <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Created By</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-4 sm:mt-0">
                      {row.admin_name} &nbsp;<span className="italic text-xs">(Note: The admin that entered the suspension might not be the same admin that decided to suspend)</span>
                    </dd>
                  </div>
                  <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Rule Violation(s)</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-4 sm:mt-0">
                      {row.rule_violation
                        ? <span>{row.rule_violation} &nbsp;<span className="italic text-xs">(Rule details coming soon!)</span></span>
                        : <span className="italic">No rule numbers associated with this suspension</span>
                      }
                      {/*TODO: Lookup rule title and body */}
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function Suspensions() {
  const [suspensions, setSuspensions] = useState([]);

  // Use hard-coded data since it should never change now that TPG is defunct
  let suspensionsJson = require('./SuspensionData.json');

   useEffect(() => {
  //   fetch(process.env.REACT_APP_API_URL + '/api/Suspensions')
  //   .then(response => response.json())
  //   .then(data => {
  //     setSuspensions(data);
  //   })
    setSuspensions(suspensionsJson);
   }, [suspensionsJson]);

  return (
    <div style={{marginLeft: "10px"}}>
      {suspensions.length === 0 ? <span>Loading suspensions...</span>
      :
      <>
      <div>
      DOD 6v6 Suspensions page from tpgleague.org in 2012 on <a className="text-blue-500 underline" href="https://web.archive.org/web/20121017034533/http://www.tpgleague.org/dod6/suspensions/">archive.org</a> <span className="italic text-xs">(Feel free to double check the data on this page against the old site)</span><br/>
      Rule Text to be included in the near future. Until then check <a className="text-blue-500 underline" href="https://web.archive.org/web/20160112155628/http://www.tpgleague.org/dod6/rules/">archive.org</a>
      </div><br/>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 450 }} size="small" aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell>Handle</StyledTableCell>
              <StyledTableCell>Reason</StyledTableCell>
              <StyledTableCell>Start Date</StyledTableCell>
              <StyledTableCell>End Date</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {suspensions.map((susp) => (
              <Row key={susp.suspid} row={susp} />
            ))}
          </TableBody>
        </Table>
      </TableContainer></>}
    </div>
  );
};

export default Suspensions;