import React, { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { useSearchParams } from "react-router-dom";

export default function TeamSearchForm({ onQuery, searchTerm}) {
  const [teamInputSearchTerm, setTeamInputSearchTerm] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("searchTerm")) {
      const term = searchParams.get("searchTerm");
      setSearchParams("");
      setTeamInputSearchTerm(term);
      executeSearch(null, term);
    }
  }, []);

  function executeSearch(e, term) {
    if (term) {
      onQuery("searching");
      fetch(process.env.REACT_APP_API_URL + '/api/Team/TeamSearch?searchString=' + term)
        .then(response => response.json())
        .then((data) => {
          onQuery(data);
        });
    } else {
      onQuery(undefined);
    }

    if (e) e.preventDefault();
  }

  function clearSearch() {
    setTeamInputSearchTerm("");
    onQuery(undefined);
    document.getElementById("teamSearchTerm").focus();
  }

  return (
    <div>
      <form onSubmit={(e) => executeSearch(e, teamInputSearchTerm)}>
        <FormControl sx={{ m: 5, width: '75ch' }} variant="outlined">
          <InputLabel htmlFor="teamSearchTerm">Team Search</InputLabel>
          <OutlinedInput
            id="teamSearchTerm"
            type="text"
            autoFocus={true}
            label="Team Search"
            value={teamInputSearchTerm}
            onChange={e => setTeamInputSearchTerm(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="search"
                  onClick={(e) => executeSearch(e, teamInputSearchTerm)}>
                    <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <IconButton  sx={{ mt: 6, ml: -3, color: 'DarkRed' }}
          aria-label="clear"
          title="Clear search form"
          onClick={clearSearch}>
            <ClearIcon />
        </IconButton>
      </form>
    </div>
  );
}