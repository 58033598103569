import { Link } from "react-router-dom";
import bracket from '../../images/bracket.png';

export default function DodSource6v6PastChamps() {
    return (
        <div>

        <h3 className="ml-1 font-bold text-lg text-blue-600">TPG Day of Defeat: Source 6v6 (1/10/06 - 07/23/12)</h3>
        <table className="pastchamps">
        <tr>
            <th title="Hover over the season number for the full date range of the season">Season</th>
            <th colspan="2">Lower</th>
            <th colspan="2">Middle</th>
            <th colspan="2">Upper</th>
        </tr>
        <tr>
            <td><span title="5/3/12 - 7/23/12">8 <span className="text-xs">(Spring 2012)</span></span></td>
            <td><i>None Declared</i>*</td>
            <td>&nbsp;</td>
            <td><Link className="underline decoration-dotted" to={"/team/1697"}>Warborn Gaming</Link></td>
            <td>&nbsp;</td>
            <td><Link className="underline decoration-dotted" to={"/team/1732"}>Gotham City Villains</Link></td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td><span title="6/12/07 - 9/18/07">6 <span className="text-xs">(Summer 2007)</span></span></td>
            <td><Link className="underline decoration-dotted" to={"/team/143"}>Excellence Violence e-sports</Link></td>
            <td className="centerrowicon"><a href="http://files.tpgleague.org/dods6v6/s6_lower.gif" target="_blank" rel="noreferrer"><img alt="bracket" src={bracket} /></a></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td><span title="3/6/07 - 6/5/07">5 <span className="text-xs">(Spring 2007)</span></span></td>
            <td><Link className="underline decoration-dotted" to={"/team/197"}>kinetic gaming</Link></td>
            <td className="centerrowicon"><a href="http://files.tpgleague.org/dods6v6/s5_lower.gif" target="_blank" rel="noreferrer"><img alt="bracket" src={bracket} /></a></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td><Link className="underline decoration-dotted" to={"/team/8"}>Nothing 2 Prove</Link></td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td><span title="11/14/06 - 2/20/07">4 <span className="text-xs">(Winter 2007)</span></span></td>
            <td><Link className="underline decoration-dotted" to={"/team/109"}>Pancake Time</Link></td>
            <td className="centerrowicon"><a href="http://files.tpgleague.org/dods6v6/s4_lower.gif" target="_blank" rel="noreferrer"><img alt="bracket" src={bracket} /></a></td>
            <td><Link className="underline decoration-dotted" to={"/team/144"}>Vice</Link></td>
            <td className="centerrowicon"><a href="http://files.tpgleague.org/dods6v6/s4_middle.gif" target="_blank" rel="noreferrer"><img alt="bracket" src={bracket} /></a></td>
            <td><Link className="underline decoration-dotted" to={"/team/8"}>Pub Starz</Link></td>
            <td className="centerrowicon"><a href="http://files.tpgleague.org/dods6v6/s4_upper.gif" target="_blank" rel="noreferrer"><img alt="bracket" src={bracket} /></a></td>
        </tr>
        <tr>
            <td><span title="7/25/06 - 10/19/06 (Originally known as season 6)">3 <span className="text-xs">(Fall 2006)</span></span></td>
            <td><a className="underline decoration-dotted" href="https://web.archive.org/web/20061028084550/http://dod2.tpgleague.org/team.info.asp?id=477" target="_blank" rel="noreferrer">Totally Cool Boys</a></td>
            <td>&nbsp;</td>
            <td><a className="underline decoration-dotted" href="https://web.archive.org/web/20061028084047/http://dod2.tpgleague.org/team.info.asp?id=384" target="_blank" rel="noreferrer">Tech-Vue</a></td>
            <td>&nbsp;</td>
            <td><a className="underline decoration-dotted" href="https://web.archive.org/web/20061028084000/http://dod2.tpgleague.org/team.info.asp?id=517" target="_blank" rel="noreferrer">Visual Gaming</a></td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td><span title="4/4/06 - July 2006 (Originally known as season 5)">2 <span className="text-xs">(Spring 2006)</span></span></td>
            <td><a className="underline decoration-dotted" href="https://web.archive.org/web/20060528115007/http://dod2.tpgleague.org/team.info.asp?id=459" target="_blank" rel="noreferrer">Go F*** Yourself</a></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td><a className="underline decoration-dotted" href="https://web.archive.org/web/20060528115007/http://dod2.tpgleague.org/team.info.asp?id=447" target="_blank" rel="noreferrer">TooN</a></td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td><span title="1/10/06 - March 2006 (Originally known as season 4)">1 <span className="text-xs">(Winter 2006)</span></span></td>
            <td><a className="underline decoration-dotted" href="https://web.archive.org/web/20060528114611/http://dod2.tpgleague.org/team.info.asp?id=311" target="_blank" rel="noreferrer">Nomadic Soliders</a></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
        </tr>
        </table>
        Notes:<em className="text-sm">
        <ul>
            <li>Season 8: &nbsp; There was no winner of the lower division because all matches were double forfeits.</li>
            <li>Season 7: &nbsp; Canceled due to lack of interest.</li>
            <li>Season 5: &nbsp; Cone of Fire plug-in began use. "Nothing 2 Prove" was declared winner of Upper as all other opponents dropped out.</li>
            <li>Season 4: &nbsp; Preseason was played in November 2006 on the old site and then teams needed to re-register on the new website.</li>
            <li>Seasons 1-3: Originally were numbered 4-6 because seasons 1-3 of TPG2 DOD 1.3 seasons were in the same timeslot.</li>
            <li>Seasons 1-3: Links to teams on archive.org are as close to the timeframe of the corresponding season as possible.</li>
        </ul>
        </em>

        </div>
    );
}